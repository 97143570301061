import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Paper,
  MobileStepper,
} from '@mui/material';
import { Prisma } from '@prisma/client';
import { useParams } from 'react-router-dom';
import showingsService from '../../api/services/showings';
import useIsMobile from '../../hooks/useIsMobile';

type ShowingWithListing = Prisma.showingsGetPayload<{
  include: {
    listings: true;
  };
}>;

const ShowingSurvey = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id } = useParams();
  const [showing, setShowing] = useState<ShowingWithListing | null>(null);
  const isMobile = useIsMobile();

  const getShowing = async () => {
    const showing = await showingsService.get(id!);
    setShowing(showing as ShowingWithListing);
  };

  const submitShowingFeedback = async (feedback: string) => {
    if (!showing || !id) return;
    const { listings, ...rest } = showing;
    await showingsService.put(id, { ...rest, feedback });
  };

  useEffect(() => {
    getShowing();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      overallImpression: '',
      homeSuitability: '',
      bestFeatures: '',
      improvements: {
        priceAdjustment: false,
        updatedFeatures: false,
        betterStaging: false,
        location: false,
        largerLayout: false,
        other: false,
        otherImprovementText: '',
      },
      purchaseLikelihood: '',
      additionalComments: '',
    },
    onSubmit: async (values) => {
      const improvementsArray = Object.entries(values.improvements)
        .filter(
          ([key, value]) =>
            value === true && key !== 'other' && key !== 'otherImprovementText'
        )
        .map(([key]) => {
          const readableText =
            {
              priceAdjustment: 'price adjustment',
              updatedFeatures: 'updated interior features',
              betterStaging: 'better staging',
              location: 'different location',
              largerLayout: 'larger layout',
            }[key] || key;
          return readableText;
        });

      if (
        values.improvements.other &&
        values.improvements.otherImprovementText
      ) {
        improvementsArray.push(values.improvements.otherImprovementText);
      }

      const improvementsString =
        improvementsArray.length === 0
          ? 'None'
          : improvementsArray.length === 1
          ? improvementsArray[0]
          : `${improvementsArray
              .slice(0, -1)
              .join(', ')} and ${improvementsArray.slice(-1)}`;

      const feedbackString = [
        `Overall Impression: ${values.overallImpression}`,
        `Home Suitability: ${values.homeSuitability}`,
        `Best Features: ${values.bestFeatures}`,
        `Suggested Improvements: ${improvementsString}`,
        `Purchase Likelihood: ${values.purchaseLikelihood}`,
        `Additional Comments: ${values.additionalComments || 'None'}`,
      ].join('\n');
      await submitShowingFeedback(feedbackString);
      setIsSubmitted(true);
    },
  });

  const steps = [
    'Overall Impression',
    'Home Suitability',
    'Best Features',
    'Improvements',
    'Purchase Likelihood',
  ];

  const handleNext = (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              How would you rate your overall impression of this home?
            </FormLabel>
            <RadioGroup
              name="overallImpression"
              value={formik.values.overallImpression}
              onChange={formik.handleChange}
              sx={{ mt: 2 }}
            >
              {[
                { value: '1', label: 'Poor' },
                { value: '2', label: 'Below Average' },
                { value: '3', label: 'Average' },
                { value: '4', label: 'Good' },
                { value: '5', label: 'Excellent' },
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case 1:
        return (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              How well does this home meet your needs?
            </FormLabel>
            <RadioGroup
              name="homeSuitability"
              value={formik.values.homeSuitability}
              onChange={formik.handleChange}
              sx={{ mt: 2 }}
            >
              {[
                'Perfect match for my needs',
                'Good match, but some compromises',
                'Neutral, unsure at this point',
                'Does not meet my needs',
                'Not at all suitable',
              ].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case 2:
        return (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              What stood out to you as the best feature(s) of the home?
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="bestFeatures"
              placeholder="Please share your thoughts..."
              value={formik.values.bestFeatures}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ mt: 2 }}
            />
          </FormControl>
        );

      case 3:
        return (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              What changes or improvements would make this home more appealing
              to you?
            </FormLabel>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {[
                { label: 'Price adjustment', key: 'priceAdjustment' as const },
                {
                  label: 'Updated interior features',
                  key: 'updatedFeatures' as const,
                },
                {
                  label: 'Better staging or presentation',
                  key: 'betterStaging' as const,
                },
                { label: 'Location or neighborhood', key: 'location' as const },
                {
                  label: 'Larger or more functional layout',
                  key: 'largerLayout' as const,
                },
                { label: 'Other', key: 'other' as const },
              ].map((item) => (
                <Grid item xs={12} sm={6} key={item.key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.improvements[item.key]}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `improvements.${item.key}`,
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={item.label}
                  />
                </Grid>
              ))}
            </Grid>
            {formik.values.improvements.other && (
              <TextField
                fullWidth
                name="improvements.otherImprovementText"
                label="Please specify"
                value={formik.values.improvements.otherImprovementText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{ mt: 2 }}
              />
            )}
          </FormControl>
        );

      case 4:
        return (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              How likely are you to consider making an offer on this home?
            </FormLabel>
            <RadioGroup
              name="purchaseLikelihood"
              value={formik.values.purchaseLikelihood}
              onChange={formik.handleChange}
              sx={{ mt: 2, mb: 3 }}
            >
              {[
                'Very likely',
                'Somewhat likely',
                'Neutral',
                'Unlikely',
                'Definitely not',
              ].map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
            <FormLabel component="legend">
              Any additional comments or feedback?
            </FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="additionalComments"
              // eslint-disable-next-line max-len
              placeholder="Please share any other thoughts about the property..."
              value={formik.values.additionalComments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{ mt: 2 }}
            />
          </FormControl>
        );

      default:
        return null;
    }
  };

  if (isSubmitted) {
    return (
      <Box sx={{ p: 3, maxWidth: 'md', mx: 'auto' }}>
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Thank You!
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Your feedback has been submitted successfully. We appreciate your
            time and input.
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 0, maxWidth: 'md', mx: 'auto' }}>
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        sx={{ mb: 2 }}
        fontWeight={500}
      >
        Showing Feedback for {showing?.listings.address}
      </Typography>

      {isMobile ? (
        <>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            Step {activeStep + 1}: {steps[activeStep]}
          </Typography>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            sx={{ mb: 3, backgroundColor: 'transparent' }}
            nextButton={<div />}
            backButton={<div />}
          />
        </>
      ) : (
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <Paper elevation={3} sx={{ p: 4 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (activeStep === steps.length - 1) {
              formik.handleSubmit(e);
            }
          }}
        >
          <Box sx={{ minHeight: '300px' }}>{renderStepContent(activeStep)}</Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default ShowingSurvey;
