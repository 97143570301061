import { useEffect, useState } from 'react';
import {
  inspections,
  vendors,
  InspectionType,
  quotes,
  QuoteStatus,
} from '@prisma/client';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  CardContent,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CloudUpload } from '@mui/icons-material';
import { startCase } from 'lodash';
import quotesService, { QuoteWithDistance } from '../../api/services/quotes';
import { useFormik } from 'formik';
import * as yup from 'yup';
// eslint-disable-next-line max-len
import DateTimeSelector from '../../components/DateTimeSelector/DateTimeSelector';
import vendorsService from '../../api/services/vendors';
import useIsMobile from '../../hooks/useIsMobile';
import uploadsService from '../../api/services/uploads';
import { useSnackbar } from 'notistack';
import { addDays } from 'date-fns';
// eslint-disable-next-line max-len
import InspectionListingDetails from '../../components/InspectionListingDetails/InspectionListingDetails';
// eslint-disable-next-line max-len
import InspectionDetails from '../../components/InspectionDetails/InspectionDetails';
// eslint-disable-next-line max-len
import StripeCheckout from '../Dashboard/Buying/ScheduleTourModal/StripeCheckout';
import { loadStripe } from '@stripe/stripe-js';
import stripeCheckoutService from '../../api/services/checkout';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import QuoteStripe from './QuoteStripe';

interface QuoteFormProps {
  quote: quotes;
  inspection: inspections;
  vendor: vendors;
  snackbar: any;
  onSubmitSuccess: () => void;
}

const ThankYouMessage = () => (
  <Card>
    <Stack spacing={2} p={4} alignItems="center">
      <Typography variant="h5" textAlign="center">
        Thank You for Submitting Your Quote
      </Typography>
      <Typography variant="body1" textAlign="center">
        We have received your quote and it has been sent to the buyer. If
        accepted, you will receive a notification to view the inspection
        details.
      </Typography>
    </Stack>
  </Card>
);

const QuoteForm = ({
  quote,
  inspection,
  vendor,
  snackbar,
  onSubmitSuccess,
}: QuoteFormProps) => {
  const isMobile = useIsMobile();
  const validationSchema = yup.object({
    quote: yup.number().required('Quote is required'),
    inspectionDate: yup.date().required('Inspection date is required'),
    sampleReport: !vendor?.sampleReportUrl
      ? yup.mixed().required('Sample report is required')
      : yup.mixed().notRequired(),
    selectedTypes: yup.array().min(1, 'Select at least one inspection type'),
  });

  const formik = useFormik({
    initialValues: {
      quote: '',
      inspectionDate: addDays(new Date(), 1),
      sampleReport: null as File | null,
      selectedTypes: inspection.type || [],
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.sampleReport) {
        const formData = new FormData();
        formData.append('file', values.sampleReport);
        formData.append('type', 'sampleReport');
        try {
          const res = await uploadsService.create(formData);
          const url = res[0].uploadResult.url;
          await vendorsService.patch(vendor.id, {
            sampleReportUrl: url,
          });
        } catch (error) {
          console.error('Error uploading sample report:', error);
          snackbar.enqueueSnackbar('Error uploading sample report', {
            variant: 'error',
          });
          return;
        }
      }
      await vendorsService.patch(vendor.id, {
        type: values.selectedTypes,
      });

      await quotesService.patch(quote.id, {
        quote: Number(values.quote),
        inspectionDate: values.inspectionDate,
      });
      onSubmitSuccess();
    },
  });

  const handleTypeChange = (type: InspectionType) => {
    const currentTypes = [...formik.values.selectedTypes];
    const typeIndex = currentTypes.indexOf(type);

    if (typeIndex === -1) {
      currentTypes.push(type);
    } else if (currentTypes.length > 1) {
      currentTypes.splice(typeIndex, 1);
    }
    formik.setFieldValue('selectedTypes', currentTypes);
  };

  return (
    <Grid container spacing={2} component={Paper} p={1}>
      <Grid xs={12} sm={6}>
        <TextField
          fullWidth
          label="Quote"
          name="quote"
          type="number"
          value={formik.values.quote}
          onChange={formik.handleChange}
          error={formik.touched.quote && Boolean(formik.errors.quote)}
          helperText={formik.touched.quote && formik.errors.quote}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <DateTimeSelector
          label="Inspection Date"
          showLabel={false}
          minDate={new Date()}
          maxDate={new Date(inspection?.inspectionDate ?? new Date())}
          value={formik.values.inspectionDate}
          onChange={(date) => formik.setFieldValue('inspectionDate', date)}
        />
      </Grid>
      <Grid xs={12}>
        <Typography variant="body2" gutterBottom>
          Services Offered in Quote
        </Typography>
        <FormGroup row>
          {inspection.type.map((type) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  checked={formik.values.selectedTypes.includes(type)}
                  onChange={() => handleTypeChange(type)}
                  disabled={
                    formik.values.selectedTypes.length === 1 &&
                    formik.values.selectedTypes.includes(type)
                  }
                />
              }
              label={startCase(type.toLowerCase())}
            />
          ))}
        </FormGroup>
        {formik.touched.selectedTypes && formik.errors.selectedTypes && (
          <FormHelperText error>
            {formik.errors.selectedTypes as string}
          </FormHelperText>
        )}
      </Grid>
      {!vendor?.sampleReportUrl && (
        <Grid xs={12} sm={12}>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
            <Typography variant="body2">
              We are missing a sample report for your company. Please upload
              one. You will only need to do this once.
            </Typography>
            <Button
              variant="contained"
              component="label"
              fullWidth
              color="secondary"
              sx={{ color: '#fff' }}
              startIcon={<CloudUpload />}
            >
              {formik.values.sampleReport
                ? formik.values.sampleReport.name
                : 'Upload Sample Report'}
              <input
                type="file"
                hidden
                accept=".pdf,.doc,.docx"
                name="sampleReport"
                onChange={(event) => {
                  formik.setFieldValue(
                    'sampleReport',
                    event.currentTarget.files?.[0]
                  );
                }}
              />
            </Button>
          </Stack>
          {formik.touched.sampleReport && formik.errors.sampleReport && (
            <Typography color="error" variant="caption">
              {formik.errors.sampleReport as string}
            </Typography>
          )}
        </Grid>
      )}
      <Grid xs={12}>
        <Button
          variant="contained"
          fullWidth
          color="highlight"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK);

const Quote = () => {
  const { id } = useParams();
  const [quote, setQuote] = useState<QuoteWithDistance | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const inspection = quote?.inspection;
  const listing = inspection?.listing;

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance: appearance as any,
  };

  const getClientSecret = async () => {
    setLoading(true);
    const res = await stripeCheckoutService.post({ amount: 1000 });
    setClientSecret(res.clientSecret);
    setLoading(false);
  };

  useEffect(() => {
    getClientSecret();
  }, []);

  const fetchQuote = async () => {
    const quote = await quotesService.find({
      where: { token: id },
    });
    setQuote(quote[0]);
  };

  useEffect(() => {
    fetchQuote();
  }, [id]);

  if (!listing || !quote || loading)
    return <Skeleton variant="rectangular" height={200} />;

  return (
    <Stack direction="column" spacing={2}>
      <Card>
        <Grid container p={2} spacing={2} alignItems="center">
          <Grid xs={12} sm={7}>
            <InspectionListingDetails
              listing={listing}
              distance={quote?.distance ?? 0}
              inspection={inspection}
              redact={quote.paid === false}
            />
          </Grid>
          <Grid xs={12} sm={5}>
            {inspection && (
              <InspectionDetails
                inspection={inspection}
                redact={quote.paid === false}
              />
            )}
          </Grid>
        </Grid>
      </Card>
      {isSubmitted && <ThankYouMessage />}
      {!isSubmitted && !quote.quote && (
        <>
          <Typography variant="h6">Details</Typography>
          <QuoteForm
            inspection={inspection}
            vendor={quote.vendor}
            quote={quote}
            snackbar={snackbar}
            onSubmitSuccess={() => setIsSubmitted(true)}
          />
        </>
      )}
      {quote.status === 'APPROVED' && !quote.paid && !isSubmitted && (
        <>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <QuoteStripe
                clientSecret={clientSecret}
                quote={quote}
                onSuccess={() => fetchQuote()}
              />
            </Elements>
          )}
        </>
      )}
    </Stack>
  );
};

export default Quote;
