import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  authenticate,
  validate as validateAuth,
} from '../../api/services/authentication';
import { users } from '@prisma/client';

interface State {
  user: users | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  user: null,
  loading: true,
  error: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await authenticate(
        credentials.email,
        credentials.password
      );
      return response.user;
    } catch (error) {
      return rejectWithValue('Login failed');
    }
  }
);

export const validate = createAsyncThunk(
  'auth/validate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await validateAuth();
      return response.user;
    } catch (error) {
      return rejectWithValue('Login failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(validate.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
