import { BedOutlined, GarageOutlined } from '@mui/icons-material';
import { Stack, styled, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { inspections, InspectionStatus, Prisma } from '@prisma/client';
import ListingImage from '../ListingImage/ListingImage';
import ShowerTub from '../svg/ShowerTub';
import SquareArea from '../svg/SquareArea';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import RedactText from '../RedactText/RedactText';

type Listing = Prisma.listingsGetPayload<{
  include: {
    uploads: true;
  };
}>;

interface ListingDetailsProps {
  listing: Listing;
  distance: number;
  inspection: inspections;
  redact?: boolean;
}

const TypographyAligned = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 16,
  textWrap: 'nowrap',
}));

const ListingDetails = ({
  listing,
  distance,
  inspection,
  redact = true,
}: ListingDetailsProps) => {
  const totalBaths = listing?.baths + listing?.halfBaths / 2;

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={5}>
        <ListingImage listing={listing} />
      </Grid>
      <Grid xs={12} sm={7}>
        <Stack direction="column" spacing={1}>
          {distance > 0 && (
            <Typography variant="body2" fontWeight={600}>
              {distance.toFixed(2)} miles from you
            </Typography>
          )}
          <Typography variant="body1" fontWeight={600}>
            {inspection.status === InspectionStatus.WAITING_QUOTE && redact ? (
              <RedactText />
            ) : (
              listing.address
            )}
          </Typography>
          <Typography variant="body2">
            {listing.city}, {listing.state} {listing.zip}
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Stack direction={'column'}>
              <Typography sx={{ opacity: 0.5, fontSize: 16, color: '#000929' }}>
                Bedrooms
              </Typography>
              <TypographyAligned>
                <BedOutlined
                  sx={{
                    marginRight: 1,
                    opacity: 1,
                    color: '#2f3640',
                    fontSize: 22,
                  }}
                />
                <strong>{listing?.beds}</strong>
              </TypographyAligned>
            </Stack>
            <Stack direction={'column'}>
              <Typography sx={{ opacity: 0.5, fontSize: 16, color: '#000929' }}>
                Bathrooms
              </Typography>
              <TypographyAligned>
                <ShowerTub
                  color="#2f3640"
                  size={20}
                  sx={{ marginRight: '8px' }}
                />
                <strong>{totalBaths}</strong>
              </TypographyAligned>
            </Stack>
            <Stack direction={'column'}>
              <Typography sx={{ opacity: 0.5, fontSize: 16, color: '#000929' }}>
                Sq. Ft.
              </Typography>
              <TypographyAligned>
                <SquareArea
                  color="#2f3640"
                  size={20}
                  sx={{ marginRight: '8px' }}
                />
                <strong>{listing?.area} ft²</strong>
              </TypographyAligned>
            </Stack>
            {listing.garageSpaces > 0 && (
              <Stack direction={'column'}>
                <Typography
                  sx={{ opacity: 0.5, fontSize: 16, color: '#000929' }}
                >
                  Garage
                </Typography>
                <TypographyAligned>
                  <GarageOutlined
                    sx={{ marginRight: 1, color: '#2f3640', fontSize: 22 }}
                  />
                  <strong>{listing?.garageSpaces}</strong>
                </TypographyAligned>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ListingDetails;
