import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prisma } from '@prisma/client';
import inspectionsService from '../../api/services/inspections';

type Inspection = Prisma.inspectionsGetPayload<{
  include: {
    vendors: true;
    transaction: true;
    listing: {
      include: {
        uploads: true;
      };
    };
  };
}>;

interface State {
  inspections: Inspection[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  inspections: [],
  loading: false,
  error: null,
};

export const fetchInspections = createAsyncThunk(
  'inspections/fetch',
  async (_params, { rejectWithValue }) => {
    try {
      const inspections = (await inspectionsService.find({})) as any;
      return inspections;
    } catch (error) {
      return rejectWithValue('Failed to fetch inspections');
    }
  }
);

const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInspections.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchInspections.fulfilled,
        (state, action: PayloadAction<Inspection[]>) => {
          state.loading = false;
          state.inspections = action.payload;
        }
      )
      .addCase(
        fetchInspections.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default inspectionsSlice.reducer;
