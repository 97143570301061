import axiosInstance from '../api';

const stripeCheckoutService = {
  post: async ({ amount }: { amount: number }) => {
    const res = await axiosInstance.post('/stripe-checkout', { amount });
    return res.data;
  },
};

export default stripeCheckoutService;
