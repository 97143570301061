import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import listingsService from '../../../api/services/listings';
import { listings } from '@prisma/client';
import useIsMobile from '../../../hooks/useIsMobile';

// GeoJSON file for Alabama (simplified version)
const alabamaGeoUrl =
  // eslint-disable-next-line max-len
  'https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json';

const StateMap = () => {
  const theme = useTheme();
  const [listings, setListings] = useState<listings[]>([]);
  const mobile = useIsMobile();

  const getListings = async () => {
    const listings = await listingsService.find({});
    setListings(listings);
  };

  useEffect(() => {
    getListings();
  }, []);

  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{
        scale: mobile ? 7000 : 5500,
        center: [-86.8, 32.8],
        rotate: [3, 4, 0],
      }}
      style={{
        width: '100%',
        height: '400px',
      }}
    >
      <Geographies geography={alabamaGeoUrl}>
        {({ geographies }) => {
          return geographies
            .filter((geo) => geo.properties.name === 'Alabama')
            .map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: theme.palette.primary.main,
                    stroke: '#FFFFFF',
                  },
                  hover: {
                    fill: theme.palette.primary.main,
                    stroke: '#FFFFFF',
                  },
                  pressed: {
                    fill: theme.palette.primary.main,
                    stroke: '#FFFFFF',
                  },
                }}
              />
            ));
        }}
      </Geographies>

      {/* Add markers for listings */}
      {listings.map((listing) => {
        if (listing.lat !== 0 && listing.lng !== 0 && listing.state === 'AL') {
          return (
            <Marker key={listing.id} coordinates={[listing.lng, listing.lat]}>
              <circle r={10} fill="#FFFFFF" stroke="#000" strokeWidth={1.5} />
            </Marker>
          );
        }
        return null;
      })}
    </ComposableMap>
  );
};

export default StateMap;
