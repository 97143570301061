import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

type UploadCreate = Partial<Prisma.uploadsCreateInput> & {
  formData?: FormData;
};

const uploadsService = {
  find: async (listingId: string, userId: string) => {
    const res = await axiosInstance.get('/uploads', {
      params: { listingId, userId },
    });
    return res.data;
  },
  create: async (data: any) => {
    const res = await axiosInstance.post('/uploads', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  },
};

export default uploadsService;
