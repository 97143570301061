/* eslint-disable max-len */
import {
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import _ from 'lodash';
import { useEffect } from 'react';

interface FeatureCardProps {
  title: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
  secondImage?: string;
  secondImageAlt?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  imageSrc,
  imageAlt,
  secondImage,
  secondImageAlt,
}) => {
  const paragraphs = description.split('\\n\\n');

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ height: '100%', justifyContent: 'space-between' }}
        >
          <div>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Stack spacing={2}>
              {paragraphs.map((paragraph, index) => (
                <Typography key={index} variant="body1">
                  {paragraph}
                </Typography>
              ))}
            </Stack>
          </div>
          <br />
          <img
            src={imageSrc}
            alt={imageAlt}
            style={{
              width: '100%',
              margin: 'auto',
              borderRadius: '10px',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            }}
          />
          {secondImage && (
            <img
              src={secondImage}
              alt={secondImageAlt}
              style={{
                width: '100%',
                margin: 'auto',
                borderRadius: '10px',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
              }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

const ProductV2: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack direction="column" gap={2} rowGap={6}>
      <span>
        <Typography variant="h5" sx={{ fontWeight: 600 }} mb={2}>
          Client Empowerment
        </Typography>
        <Grid2 container spacing={4} alignItems="stretch">
          <Grid2 xs={12} sm={6}>
            <FeatureCard
              title="Guided Onboarding"
              description="Your sellers can start listings independently through our intuitive step-by-step process. They can enter property details, describe renovations, their priority for selling, sign the listing agreement, and more - all on their own.\n\nThis saves you valuable time while keeping clients engaged and in control of their listing journey."
              imageSrc="/renovations.png"
              imageAlt="Guided Onboarding"
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <FeatureCard
              title="Buyer Enablement"
              description="Buyers can schedule tours on their own for any property, whether it is with you or utilizing our showing agent network. When they're ready to make an offer, our step by step process guides them through the process for your review.\n\nThis allows you to provide alternative service models to your clients and expand your business without growing your team."
              imageSrc="/Product1.png"
              imageAlt="Buyer Enablement"
            />
          </Grid2>
        </Grid2>
      </span>
      <span>
        <Typography variant="h5" sx={{ fontWeight: 600 }} mb={2}>
          Transaction Management
        </Typography>
        <div
          style={{
            filter: `drop-shadow(0 8px 16px ${theme.palette.primary.main}40)`,
          }}
        >
          <Grid2 container spacing={4} alignItems="stretch">
            <Grid2 xs={12} sm={6}>
              <FeatureCard
                title="Centralized Dashboard"
                description="Your transactions are centralized in one place. You can easily track the progress of each transaction, receive automated notifications for updates, and invite involved parties.\n\nThis helps you stay organized and focused on your clients' needs."
                imageSrc="/demo_transactions.png"
                imageAlt="Guided Onboarding"
              />
            </Grid2>
            <Grid2 xs={12} sm={6}>
              <FeatureCard
                title="AI-Powered Vendor Coordination"
                description="Our AI-powered inspection coordination tool helps you manage your inspections. It automatically requests quotes from inspectors, both preferred and those in your area, and sends these for review to your clients.\n\nThis helps you manage your inspections and keep your clients informed."
                imageSrc="/inspections_quote_approve.png"
                imageAlt="Guided Onboarding"
                secondImage="/inspections_screen.png"
                secondImageAlt="Guided Onboarding"
              />
            </Grid2>
          </Grid2>
        </div>
      </span>
      <span>
        <Typography variant="h5" sx={{ fontWeight: 600 }} mb={2}>
          Modern Showing Solutions
        </Typography>
        <Grid2 container spacing={4} alignItems="stretch">
          <Grid2 xs={12} sm={6}>
            <FeatureCard
              title="Smart Platform Connected Lockbox"
              description="Our provided platform connected lockbox allows potential buyers to schedule showings and lets you know when they check in and out.\n\nStop renting or buying lockboxes and save money."
              imageSrc="/lubn.webp"
              imageAlt="Guided Onboarding"
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <FeatureCard
              title="Showing Feedback Surveys"
              description="Showing feedback is more than just a question. It's a way to get feedback from buyers after the showing and use that feedback to improve your marketing and sales strategies. Our surveys and follow ups are designed to be easy to use and provide valuable feedback."
              imageSrc="/inspections_quote_approve.png"
              imageAlt="Guided Onboarding"
              secondImage="/inspections_screen.png"
              secondImageAlt="Guided Onboarding"
            />
          </Grid2>
        </Grid2>
      </span>
    </Stack>
  );
};

export default ProductV2;
