import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { StyledNavLink } from './StyledNavLink';
import { IRoute } from '../../interfaces';
import { AccountType } from '@prisma/client';

interface MenuListItemProps {
  route: IRoute;
  currentAccountType: AccountType | null | undefined;
}

export const MenuListItem: React.FC<MenuListItemProps> = ({
  route,
  currentAccountType,
}) => {
  const theme = useTheme();
  const isPremiumLocked =
    route.accountType === 'PREMIUM' && currentAccountType !== 'PREMIUM';

  const handleClick = (e: React.MouseEvent) => {
    if (isPremiumLocked) {
      e.preventDefault();
      // Add notification/modal logic here
    }
  };

  return (
    <ListItem
      sx={{
        '&:hover': {
          backgroundColor: isPremiumLocked ? 'inherit' : 'action.hover',
          cursor: isPremiumLocked ? 'not-allowed' : 'pointer',
        },
      }}
      component={isPremiumLocked ? 'div' : StyledNavLink}
      to={route.path}
      onClick={handleClick}
    >
      <ListItemIcon>
        <route.icon
          sx={{
            color: isPremiumLocked ? theme.palette.grey[400] : 'inherit',
          }}
        />
      </ListItemIcon>
      <ListItemText>
        <Typography
          variant="body2"
          fontWeight={500}
          color={isPremiumLocked ? theme.palette.grey[400] : 'inherit'}
        >
          {route.title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
