// src/componetns/Footer.tsx

import React, { FC, ReactElement } from 'react';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { Facebook, Instagram } from '@mui/icons-material';

export const Footer: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        bottom: 0,
        position: 'relative',
        p: 2,
      }}
    >
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <Grid container>
          <Grid xs={12} sm={6}>
            <Link to="/">
              <img src="/new_logo_light.png" width="200" />
            </Link>
            <Typography variant="body2" fontWeight={600}>
              205-973-0007
            </Typography>
            <Grid xs={12}>
              <Typography color="textSecondary" variant="caption">
                &copy;{`${new Date().getFullYear()} Homepoint Brokerage LLC`} |
                AL #165855
              </Typography>
              <br />
              <Typography color="textSecondary" variant="caption" fontSize={12}>
                1500 1st Ave N Mailbox #28 Birmingham, AL 35203
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6}>
            <Stack direction="row" justifyContent={'space-between'}>
              <Stack direction="column">
                <Typography variant="subtitle1" fontWeight={600}>
                  Company
                </Typography>
                <Typography variant="subtitle2">
                  <Link to="/listings" style={{ textDecoration: 'none' }}>
                    Homes for Sale
                  </Link>
                </Typography>
                <Typography variant="subtitle2">
                  <Link to="/press" style={{ textDecoration: 'none' }}>
                    Press
                  </Link>
                </Typography>
                <Typography variant="subtitle2">
                  <Link to="/policies" style={{ textDecoration: 'none' }}>
                    Policies
                  </Link>
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography variant="subtitle1" fontWeight={600}>
                  Social
                </Typography>
                <Stack direction="row" spacing={1}>
                  <a
                    href="https://www.facebook.com/homepointco"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.instagram.com/homepointco/"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram />
                  </a>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
