import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Image,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import * as QRC from 'qrcode';
import logo from '/new_logo_light.png';
import { useEffect, useState } from 'react';

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  logo: {
    width: 200,
    height: 80,
    objectFit: 'contain',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  qrCode: {
    margin: '20px 0',
  },
  instructions: {
    fontSize: 16,
    textAlign: 'center',
  },
});

// Create PDF Document component
const QRDocument = ({ qrCodeUrl }: { qrCodeUrl: string }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        <Image src={logo} style={styles.logo} />
        <Text style={styles.title}>Open House Check-In</Text>
        <Image src={qrCodeUrl} style={styles.qrCode} />
        <Text style={styles.instructions}>
          Scan this QR code to check in to the open house.
        </Text>
      </View>
    </Page>
  </Document>
);

const QRCode = ({ listingId }: { listingId: string }) => {
  // eslint-disable-next-line max-len
  const qrCodeUrl = `${window.location.origin}/listings/${listingId}?openhouse=true`;
  const [qrCode, setQRCode] = useState<string | null>(null);

  const generatePDF = async () => {
    const qrCode = await QRC.toDataURL(qrCodeUrl);
    setQRCode(qrCode);
  };

  useEffect(() => {
    generatePDF();
  }, []);

  if (!qrCode) return null;

  return (
    <PDFDownloadLink
      document={<QRDocument qrCodeUrl={qrCode} />}
      fileName={`open-house-qr-${listingId}.pdf`}
    >
      <Button variant="contained">Print Open House QR Code</Button>
    </PDFDownloadLink>
  );
};

export default QRCode;
