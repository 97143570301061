import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AutoComplete from '../Listings/Autocomplete';
import { AddressSelection } from '../../types/AddressSelection';
import Seller from './components/Seller';
import Buyer from './components/Buyer';
import FAQ from './components/FAQ';
import WaitlistForm from './WaitlistForm';
import { UserType } from '@prisma/client';
import _ from 'lodash';
import StateMap from './components/StateMap';

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(0.375),
  '& .MuiToggleButton-root': {
    borderRadius: theme.spacing(2.5),
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    padding: theme.spacing(0.5, 2),
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const LandingV1 = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const veryMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [address, setAddress] = useState<AddressSelection | null>(null);
  const [searchParams] = useSearchParams();
  const typeFromUrl = searchParams.get('type');
  const [type, setType] = useState<UserType>(
    typeFromUrl ? (_.upperCase(typeFromUrl) as UserType) : 'SELLER'
  );
  const [price, setPrice] = useState(400000);

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const id = location.hash.substring(1); // Remove the '#' character
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  let signupUrl = '/signup';
  const params = new URLSearchParams();
  if (address && address.id) {
    params.append('id', address.id);
  }
  if (address && address.address) {
    params.append('address', encodeURIComponent(address.address));
  }
  if (type) {
    params.append('type', type);
  }
  if (params.toString()) {
    signupUrl += `?${params.toString()}`;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <Stack direction={'column'} textAlign={mobile ? 'center' : undefined}>
            <span>
              <Box mb={2}>
                <Typography variant={'h3'} fontWeight={700}>
                  Sell Your Home. Keep More Money.
                </Typography>
                <Typography variant="h6" fontSize={mobile ? 20 : undefined}>
                  Our easy-to-use platform helps you sell your home with agent
                  support—all for a flat fee.
                </Typography>
              </Box>
              <Card sx={{ p: 2, pt: 1 }}>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" alignItems={'center'}>
                    <Typography variant="h6" fontWeight={600}>
                      I am a
                    </Typography>
                    <CustomToggleButtonGroup
                      value={type}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setType(value);
                        }
                      }}
                      sx={{ ml: 1, mb: 1 }}
                    >
                      <ToggleButton value={'SELLER'} color="primary">
                        <Typography variant="h6" fontWeight={600}>
                          Seller
                        </Typography>
                      </ToggleButton>
                      <ToggleButton value={'BUYER'} color="primary">
                        <Typography variant="h6" fontWeight={600}>
                          Buyer
                        </Typography>
                      </ToggleButton>
                    </CustomToggleButtonGroup>
                  </Stack>
                  <Stack direction="row">
                    <AutoComplete
                      handleChange={(e) => setAddress(e)}
                      property={address}
                    />
                    {!mobile && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        sx={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          textWrap: 'nowrap',
                          minWidth: 100,
                        }}
                        onClick={() => {
                          navigate(signupUrl);
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </Stack>
                  <Typography variant="body1" fontWeight={600}>
                    {type === 'SELLER'
                      ? "Sign up today, list when you're ready"
                      : 'Schedule a showing, make an offer, and more.'}
                  </Typography>
                  {mobile && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        textWrap: 'nowrap',
                      }}
                      onClick={() => {
                        navigate(signupUrl);
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Stack>
              </Card>
            </span>
          </Stack>
        </Grid>
        {!veryMobile && (
          <Grid sm={6}>
            <img
              src={'/hp_action1.jpg'}
              style={{ width: '100%', borderRadius: 8 }}
            />
          </Grid>
        )}
      </Grid>
      {/* <Featured /> */}
      {!mobile && <br />}
      {type === 'BUYER' ? (
        <Buyer mobile={mobile} price={price} handlePriceChange={setPrice} />
      ) : (
        <Seller mobile={mobile} price={price} handlePriceChange={setPrice} />
      )}
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="lg">
        <Stack direction="column" mt={4} spacing={2}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 700 }}
            textAlign={'center'}
          >
            About Homepoint
          </Typography>
          <Grid container>
            <Grid xs={12} sm={5}>
              <Typography variant="body2" textAlign={'center'} fontWeight={600}>
                Our Customers
              </Typography>
              <StateMap />
            </Grid>
            <Grid xs={12} sm={7}>
              <Stack
                direction="column"
                spacing={2}
                textAlign={mobile ? 'center' : undefined}
              >
                <Typography variant="h5" fontWeight={600}>
                  A Smarter, More Affordable Way to Sell Your Home
                </Typography>
                <Typography variant="body1">
                  With Homepoint, you get a simple, transparent way to sell your
                  home with the same expert services—at a fraction of the cost.
                  Our easy-to-use platform puts you in control, while our
                  support team is here when you need us.
                </Typography>
                <ul>
                  <li>
                    <b>Keep More of Your Money:</b> Pay just $5,000 at
                    closing—no hidden fees.
                  </li>
                  <li>
                    <b>Full Support, When You Need It:</b> We&apos;re with you
                    every step of the way.
                  </li>
                  <li>
                    <b>A Stress-Free Process:</b> From listing to closing, we
                    make it easy.
                  </li>
                </ul>
              </Stack>
            </Grid>
          </Grid>
          <Box textAlign={'center'}>
            <Typography variant="h5" fontWeight={600} mb={2}>
              Demo
            </Typography>
            <div
              style={{
                position: 'relative',
                boxSizing: 'content-box',
                aspectRatio: '1.1635220125786163',
                maxHeight: 650,
                width: '100%',
              }}
            >
              <iframe
                data-version="2"
                // eslint-disable-next-line max-len
                src="https://app.supademo.com/showcase/embed/cm48nkxis0v0n5ewaf5445m2d?embed_v=2"
                loading="lazy"
                title="Selling with Homepoint"
                allow="clipboard-write"
                frameBorder="0"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              ></iframe>
            </div>
          </Box>
          <Box textAlign={'center'}>
            <Button
              variant="contained"
              fullWidth={mobile}
              color="primary"
              sx={{
                minWidth: mobile ? 'auto' : 200, // Ensures button has auto width on mobile, 300px otherwise
                whiteSpace: 'nowrap',
                width: mobile ? '100%' : 300, // Ensures full width on mobile, 300px otherwise
                fontWeight: 700,
              }}
              onClick={() => navigate('/product')}
            >
              More How It Works
            </Button>
          </Box>
        </Stack>
      </Container>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      {/* Pricing */}
      <Stack direction="column" alignItems={'center'} mt={4} spacing={1}>
        <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2 }}>
          Pricing
        </Typography>
        <Typography variant="h5" align="center">
          <b>$5,000</b> at home closing
        </Typography>
        <Typography align="center" variant="body1">
          No upfront costs. Just expert support and thousands in savings.
        </Typography>
      </Stack>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="md">
        <Stack
          direction="column"
          alignItems={'center'}
          pb={2}
          textAlign={'center'}
          spacing={1}
        >
          <Typography variant="h5" fontWeight={600}>
            Ready to Sell Your Home and Save Thousands?
          </Typography>
          <Typography variant="body1">
            {type === 'SELLER'
              ? // eslint-disable-next-line max-len, quotes
                `Sign up today and start your listing whenever you're ready—with full support and no pressure.`
              : // eslint-disable-next-line max-len
                'Ready to buy your next home and save thousands? Get started today by signing up for an account. Experience the Homepoint difference and keep more of your hard-earned money.'}
          </Typography>
          <Button
            variant="contained"
            fullWidth={mobile}
            color="highlight"
            sx={{
              minWidth: mobile ? 'auto' : 200, // Ensures button has auto width on mobile, 300px otherwise
              whiteSpace: 'nowrap',
              width: mobile ? '100%' : 250, // Ensures full width on mobile, 300px otherwise
              fontWeight: 700,
              fontSize: 18,
            }}
            onClick={() => navigate('/signup')}
          >
            Get Started
          </Button>
        </Stack>
      </Container>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <FAQ />
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="md">
        <Stack
          direction="column"
          alignItems={'center'}
          pb={2}
          textAlign={'center'}
          spacing={2}
        >
          <Typography variant="h5" fontWeight={600}>
            Stay Informed with Home Selling Tips
          </Typography>
          <Typography variant="body1">
            Get helpful advice, market updates, and Homepoint news straight to
            your inbox.
          </Typography>
          <WaitlistForm mobile={mobile} title="Sign Up" color="primary" />
        </Stack>
      </Container>
    </>
  );
};

export default LandingV1;
