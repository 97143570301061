import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prisma, vendors } from '@prisma/client';
import vendorsService from '../../api/services/vendors';

interface State {
  vendors: vendors[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  vendors: [],
  loading: false,
  error: null,
};

export const fetchVendors = createAsyncThunk(
  'vendors/fetch',
  async (_params, { rejectWithValue }) => {
    try {
      const vendors = await vendorsService.find();
      return vendors;
    } catch (error) {
      return rejectWithValue('Failed to fetch vendors');
    }
  }
);

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchVendors.fulfilled,
        (state, action: PayloadAction<vendors[]>) => {
          state.loading = false;
          state.vendors = action.payload;
        }
      )
      .addCase(fetchVendors.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default vendorsSlice.reducer;
