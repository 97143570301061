import { PaymentElement } from '@stripe/react-stripe-js';

const StripeCheckout: React.FC = () => {
  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <PaymentElement
      id="payment-element"
      options={paymentElementOptions as any}
    />
  );
};

export default StripeCheckout;
