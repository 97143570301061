import { useParams } from 'react-router-dom';
// eslint-disable-next-line max-len
import InspectionListingDetails from '../../components/InspectionListingDetails/InspectionListingDetails';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Prisma } from '@prisma/client';
import quotesService, { QuoteWithDistance } from '../../api/services/quotes';
import { startCase } from 'lodash';
import { formatCurrency } from '../../utils/formatCurrency';
import useIsMobile from '../../hooks/useIsMobile';
// eslint-disable-next-line max-len
import InspectionDetails from '../../components/InspectionDetails/InspectionDetails';

const QuoteActions = ({
  quote,
  onApprove,
}: {
  quote: Prisma.quotesGetPayload<{ include: { vendor: true } }>;
  onApprove: (id: string) => void;
}) => {
  const isMobile = useIsMobile();
  const readyToApprove =
    quote.quote && quote.inspectionDate && quote.status === 'PENDING';

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      {quote.vendor.sampleReportUrl && quote.status === 'PENDING' && (
        <Button
          variant="contained"
          color="highlight"
          href={quote.vendor.sampleReportUrl}
          target="_blank"
          fullWidth={isMobile}
        >
          View Sample Report
        </Button>
      )}
      {readyToApprove && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => onApprove(quote.id)}
          fullWidth={isMobile}
        >
          Approve
        </Button>
      )}
      {quote.status === 'APPROVED' && (
        <Chip
          label="Approved"
          color="success"
          sx={{ width: isMobile ? '100%' : 'auto' }}
        />
      )}
    </Stack>
  );
};

const InspectionsDetail = () => {
  const { id } = useParams();
  const [quotes, setQuotes] = useState<QuoteWithDistance[]>([]);
  const isMobile = useIsMobile();
  const inspection = quotes[0]?.inspection;

  const fetchQuotes = async () => {
    const quotes = await quotesService.find({
      where: { inspectionId: id },
    });
    setQuotes(quotes);
  };

  const handleApprove = async (quoteId: string) => {
    await quotesService.patch(quoteId, { status: 'APPROVED' });
    fetchQuotes();
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  if (!inspection || inspection.listing === null)
    return <div>Inspection not found</div>;

  return (
    <Stack direction="column" spacing={2}>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid xs={12} sm={7}>
              <InspectionListingDetails
                listing={inspection.listing}
                distance={0}
                inspection={inspection}
                redact={false}
              />
            </Grid>
            <Grid xs={12} sm={5}>
              <InspectionDetails inspection={inspection} redact={false} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h6">Quotes</Typography>
        <Typography variant="body2" color="text.secondary">
          Quotes not approved by the inspection window will be declined after
          the inspection window is closed.
        </Typography>
      </Stack>
      {isMobile ? (
        <Stack spacing={2}>
          {quotes.map((quote) => {
            return (
              <Paper key={quote.id} sx={{ p: 2 }}>
                <Stack spacing={1}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {quote.vendor.name}
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                      Price
                    </Typography>
                    <Typography>
                      {quote.quote ? formatCurrency(quote.quote) : 'Pending'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                      Inspection Date
                    </Typography>
                    <Typography>
                      {quote.inspectionDate
                        ? new Date(quote.inspectionDate).toLocaleDateString()
                        : 'Pending'}
                    </Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                      Services
                    </Typography>
                    <Typography>
                      {quote.vendor.type
                        .map((type) => startCase(type.toLowerCase()))
                        .join(', ')}
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <QuoteActions quote={quote} onApprove={handleApprove} />
                  </Stack>
                </Stack>
              </Paper>
            );
          })}
        </Stack>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vendor</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Inspection Date</TableCell>
                <TableCell>Services</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {quotes.map((quote) => {
                return (
                  <TableRow key={quote.id}>
                    <TableCell>{quote.vendor.name}</TableCell>
                    <TableCell>
                      {quote.quote ? formatCurrency(quote.quote) : 'Pending'}
                    </TableCell>
                    <TableCell>
                      {quote.inspectionDate
                        ? new Date(quote.inspectionDate).toLocaleDateString()
                        : 'Pending'}
                    </TableCell>
                    <TableCell>
                      {quote.vendor.type
                        .map((type) => startCase(type.toLowerCase()))
                        .join(', ')}
                    </TableCell>
                    <TableCell>
                      <QuoteActions quote={quote} onApprove={handleApprove} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default InspectionsDetail;
