import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { InspectionStatus, Prisma } from '@prisma/client';
import { startCase } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import RedactText from '../RedactText/RedactText';

interface InspectionDetailsProps {
  inspection: Prisma.inspectionsGetPayload<{ include: { user: true } }>;
  redact?: boolean;
}

const InspectionDetails = ({
  inspection,
  redact = true,
}: InspectionDetailsProps) => {
  const agentName =
    inspection.user?.firstName + ' ' + inspection.user?.lastName;
  const agentPhone = inspection.user?.phone;
  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={600}>
            Services Requested
          </Typography>
          <Typography variant="body2">
            {inspection.type.map((t) => startCase(t.toLowerCase())).join(', ')}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} sm={6}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={600}>
            Inspection Window
          </Typography>
          <Typography variant="body2">
            {inspection.inspectionDate
              ? `${new Date().toLocaleDateString()} - ${new Date(
                  inspection.inspectionDate
                ).toLocaleDateString()}`
              : 'N/A'}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={600}>
            Requested By
          </Typography>
          <Typography variant="body2">
            <Stack direction="row" spacing={1} alignItems="center">
              <b>Agent</b>
              <span style={{ width: '100%' }}>
                {redact &&
                inspection.status === InspectionStatus.WAITING_QUOTE ? (
                  <RedactText />
                ) : (
                  agentName +
                  ' - ' +
                  (agentPhone
                    ? parsePhoneNumber(agentPhone)?.formatNational()
                    : '')
                )}
              </span>
            </Stack>
          </Typography>
          <Typography variant="body2">
            <Stack direction="row" spacing={1} alignItems="center">
              <b>Buyer</b>
              <span style={{ width: '100%' }}>
                {redact &&
                inspection.status === InspectionStatus.WAITING_QUOTE ? (
                  <RedactText />
                ) : (
                  parsePhoneNumber(
                    inspection.buyerPhone ?? ''
                  )?.formatNational()
                )}
              </span>
            </Stack>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default InspectionDetails;
