import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { showings } from '@prisma/client';
import { isBefore } from 'date-fns';
import showingsService from '../../../../../api/services/showings';
import { useAppDispatch } from '../../../../../store/store';
import { fetchShowings } from '../../../../../store/slices/showings';
import Circle from '../../../../../components/Circle/Circle';
import { useState } from 'react';
import { useAuth } from '../../../../../contexts/AuthContext';
import formatTime from '../../../../../utils/formatTime';

interface Props {
  showing?: showings;
  vertical?: boolean;
}

const ShowingTime: React.FC<Props> = ({ showing, vertical }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  if (!showing) {
    return <Typography variant="body2">None</Typography>;
  }

  const updateShowing = async (e: any, confirm: boolean) => {
    setLoading(true);
    e.stopPropagation();

    await showingsService.patch(showing?.id, {
      accepted: confirm,
    });
    setLoading(false);
    dispatch(fetchShowings());
  };

  const cancelShowing = async (e: any) => {
    setLoading(true);
    e.stopPropagation();

    await showingsService.patch(showing?.id, {
      accepted: null,
    });
    setLoading(false);
    dispatch(fetchShowings());
  };

  const showingTime = new Date(showing.proposedTime);
  const showingText = formatTime(showingTime);
  const showingPending = showing.accepted === false;
  const completed = showing.accepted && isBefore(showingTime, new Date());
  const cancelled = showing.accepted === null;

  let text = 'Accepted';
  if (showingPending) {
    text = 'Pending';
  } else if (completed) {
    text = 'Completed';
  } else if (cancelled) {
    text = 'Cancelled';
  }

  return (
    <Stack direction={vertical ? 'column' : 'row'} spacing={1}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography variant="body2">{showingText}</Typography>
        {completed === false && (
          <Circle
            size={15}
            color={showingPending ? '#f1c40f' : 'success.main'}
          />
        )}
        {cancelled === true && <Circle size={15} color="error.main" />}
        <Typography variant="caption" fontWeight={600}>
          {text}
        </Typography>
        {loading && <CircularProgress size={20} />}
      </Stack>
      {/* Only show the buttons if the user is the seller and the showing is pending */}
      {showingPending && !loading && currentUser?.id !== showing.userId && (
        <>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => updateShowing(e, false)}
            fullWidth={vertical}
          >
            Deny
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ color: 'success.contrastText' }}
            onClick={(e) => updateShowing(e, true)}
            fullWidth={vertical}
          >
            Confirm
          </Button>
        </>
      )}
      {/* Only show the cancel button if the user is the buyer and the showing is accepted */}
      {showing.accepted &&
        !completed &&
        !loading &&
        currentUser?.id === showing.userId && (
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => cancelShowing(e)}
          >
            Cancel
          </Button>
        )}
    </Stack>
  );
};

export default ShowingTime;
