import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { groupBy } from 'lodash';

export const selectShowings = (state: RootState) => state.showings.showings;

export const selectSellingShowings = createSelector(
  [selectShowings],
  (showings) => {
    return showings
      .filter((showing) => showing.listings.type === 'SELLER')
      .sort(
        (a, b) =>
          new Date(b.proposedTime).getTime() -
          new Date(a.proposedTime).getTime()
      );
  }
);

export const selectShowingsGroupedByBuyer = createSelector(
  [selectSellingShowings],
  (showings) => {
    return groupBy(showings, 'userId');
  }
);

export const selectBuyingShowings = createSelector(
  [selectShowings],
  (showings) => {
    return showings
      .filter((showing) => showing.listings.type === 'BUYER')
      .sort(
        (a, b) =>
          new Date(b.proposedTime).getTime() -
          new Date(a.proposedTime).getTime()
      );
  }
);
