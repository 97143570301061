import { createSelector } from '@reduxjs/toolkit';
import { selectSellingShowings } from './showings';
import { selectSellingOffers } from './offers';
import { selectMessages } from './messages';
import { selectUser } from './auth';
import { showings, users } from '@prisma/client';
import { messagesWithSenderAndReceiver } from '../slices/messages';
import { offersWithUser } from '../slices/offers';
import _ from 'lodash';

export const selectBuyers = createSelector(
  [selectSellingOffers, selectSellingShowings, selectMessages, selectUser],
  (offers, showings, messages, user) => {
    const offerUsers = offers.map((offer) => offer.user);
    const showingUsers = showings.map((showing) => showing.user);
    const messageUsers = messages.flatMap((message) =>
      _.pick(message, ['receiver', 'sender'])
    );
    const uniqueUserIds = _.uniq(
      messageUsers.flatMap(({ receiver, sender }) => [receiver, sender])
    );
    const otherUserIds = uniqueUserIds.filter((mUser) => mUser.id !== user!.id);
    const allUsers = [...offerUsers, ...showingUsers, ...otherUserIds];
    const userMap = new Map();
    allUsers.forEach((user) => {
      userMap.set(user.id, user);
    });

    // Return unique user objects
    return Array.from(userMap.values());
  }
);

interface Details {
  offers: offersWithUser;
  messages: messagesWithSenderAndReceiver;
  showings: showings;
  buyer: users;
  listingId: string;
}

const getListingIdFromDetails = (
  details: Omit<Details, 'listingId'>
): string | undefined => {
  if (details.messages.length > 0) {
    return details.messages[0].listingId;
  }
  if (details.offers) {
    return details.offers.listingId;
  }
  if (details.showings) {
    return details.showings.listingId;
  }
};

export const selectBuyerDetailsById = (buyerId: string) =>
  createSelector(
    [selectSellingOffers, selectSellingShowings, selectMessages],
    (offers, showings, messages) => {
      const buyerOffers = offers.find((offer) => offer.userId === buyerId);
      const buyerShowings = showings.find(
        (showing) => showing.userId === buyerId
      );
      const sentMessages = messages.filter(
        (message) => message.senderId === buyerId
      );
      const receivedMessages = messages.filter(
        (message) => message.receiverId === buyerId
      );

      const buyer = {
        ...buyerOffers?.user,
        ...buyerShowings?.user,
        ...(sentMessages[0] ? { ...sentMessages[0].sender } : {}),
      };

      let details = {
        offers: buyerOffers,
        showings: buyerShowings,
        messages: [...sentMessages, ...receivedMessages],
        buyer,
      } as any;
      details = {
        ...details,
        listingId: getListingIdFromDetails(details),
      };
      return details;
    }
  );
