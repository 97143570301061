import { useNavigate, useParams } from 'react-router-dom';
import pricingOpinionsService, {
  PricingOpinionWithListings,
} from '../../api/services/priceOpinion';
import { useEffect, useState } from 'react';
import { Card, CircularProgress, Stack, Typography } from '@mui/material';
import ListingCard from '../../components/ListingCard/ListingCard';
import compsService from '../../api/services/comps';
import { comps } from '@prisma/client';
import CompsTable from '../EditListing/forms/PricingForm/CompsTable';
import PriceSelection from './PriceSelection';

const PriceOpinion = () => {
  const { id } = useParams();
  const [priceOpinion, setPriceOpinion] =
    useState<PricingOpinionWithListings | null>(null);
  const [comps, setComps] = useState<comps[] | null>(null);
  const navigate = useNavigate();

  const getPriceOpinion = async (id: string) => {
    const res = await pricingOpinionsService.get(id);
    setPriceOpinion(res);
  };

  const getComps = async (id: string) => {
    const res = await compsService.find(id);
    setComps(res.comps);
  };

  useEffect(() => {
    if (id) {
      getPriceOpinion(id);
    }
  }, [id]);

  useEffect(() => {
    if (priceOpinion?.listings) {
      getComps(priceOpinion.listings.id);
    }
  }, [priceOpinion?.listings]);

  const handleApprove = () => {
    if (priceOpinion?.id) {
      getPriceOpinion(priceOpinion.id);
      navigate(
        // eslint-disable-next-line max-len
        '/selling?message=Thank you for approving your pricing opinion. Homepoint has been notified.'
      );
    }
  };

  if (!priceOpinion?.listings) {
    return <CircularProgress />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Card>
        {priceOpinion?.listings && (
          <ListingCard
            listing={priceOpinion?.listings}
            showViewButton={false}
            showEditButton={false}
            showAdjustPriceButton={false}
          />
        )}
      </Card>
      <PriceSelection priceOpinion={priceOpinion} onApprove={handleApprove} />
      <Typography variant="h6">Homepoint Remarks</Typography>
      <Card sx={{ p: 2 }}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: priceOpinion?.notes ?? 'No remarks',
          }}
        />
      </Card>
      {comps && <CompsTable comps={comps} />}
    </Stack>
  );
};

export default PriceOpinion;
