import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import listingsService from '../../api/services/listings';
import { Prisma } from '@prisma/client';

export type listingsWithUploadsAndOpenHouses = Prisma.listingsGetPayload<{
  include: { uploads: true; openHouses: true; user: true };
}>;

interface State {
  listings: listingsWithUploadsAndOpenHouses[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  listings: [],
  loading: false,
  error: null,
};

export const fetchListings = createAsyncThunk(
  'listings/fetch',
  async (params: Prisma.listingsFindManyArgs, { rejectWithValue }) => {
    try {
      const listings = await listingsService.find({ params });
      return listings as listingsWithUploadsAndOpenHouses[];
    } catch (error) {
      return rejectWithValue('Failed to fetch listings');
    }
  }
);

const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchListings.fulfilled,
        (state, action: PayloadAction<listingsWithUploadsAndOpenHouses[]>) => {
          state.loading = false;
          state.listings = action.payload;
        }
      )
      .addCase(fetchListings.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = listingsSlice.actions;
export default listingsSlice.reducer;
