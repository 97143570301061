import { AxiosResponse } from 'axios';
import axiosInstance from '../api';
import { Prisma, quotes } from '@prisma/client';

export type QuoteWithDistance = Prisma.quotesGetPayload<{
  include: {
    vendor: true;
    inspection: {
      include: {
        user: true;
        listing: {
          include: {
            uploads: true;
          };
        };
      };
    };
  };
}> & {
  distance: number;
};

const quotesService = {
  find: async (params: Prisma.quotesFindManyArgs) => {
    const res: AxiosResponse<QuoteWithDistance[]> = await axiosInstance.get(
      '/quotes',
      {
        params,
      }
    );
    return res.data;
  },
  patch: async (id: string, data: Prisma.quotesUpdateInput) => {
    const res: AxiosResponse<quotes> = await axiosInstance.patch(
      `/quotes/${id}`,
      data
    );
    return res.data;
  },
};

export default quotesService;
