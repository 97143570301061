import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import { useEffect } from 'react';
import { fetchShowings } from '../../../store/slices/showings';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';
import { Prisma } from '@prisma/client';
import ShowingTime from '../../Dashboard/Selling/Buyers/components/ShowingTime';

const AdminShowings: React.FC = () => {
  const showings = useSelector((state: RootState) => state.showings.showings);
  const sortedShowings = [...showings].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchShowings());
  }, []);

  return (
    <>
      <Typography variant="h6" pb={2}>
        Showings
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {sortedShowings.length > 0 && (
              <TableRow>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    User
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Showing
                  </Typography>
                </TableCell>
                <TableCell>Other Times</TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    User Type
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {sortedShowings.map(
              (
                showing: Prisma.showingsGetPayload<{ include: { user: true } }>
              ) => {
                return (
                  <TableRow key={showing.id}>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Typography>
                          {showing.user.firstName} {showing.user.lastName}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <ShowingTime showing={showing} />
                    </TableCell>
                    <TableCell>
                      {showing.otherTimes.map((time: Date, index) => {
                        return (
                          <Typography key={index}>
                            {new Date(time).toLocaleString()}
                          </Typography>
                        );
                      })}
                    </TableCell>
                    <TableCell>{showing.user.type}</TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminShowings;
