import { offers } from '@prisma/client';
import { differenceInCalendarDays } from 'date-fns';

interface ScoredOffer extends offers {
  proceeds: number;
  baseScore: number;
  relativeScore: number;
}

const SCORE_WEIGHTS = {
  CASH_OFFER: 0.2,
  PROCEEDS: 0.2,
  CLOSING_DATE: 0.1,
  INSPECTION_PERIOD: 0.1,
  CONTINGENT: 0.2,
  EARNEST_MONEY: 0.05,
} as const;

export interface ScoreBreakdown {
  cashOffer: number;
  proceeds: number;
  closingDate: number;
  inspectionPeriod: number;
  contingent: number;
  earnestMoney: number;
  total: number;
}

export const calculateOfferScore = (
  offer: offers,
  homePrice: number
): ScoreBreakdown => {
  const cashOfferScore = offer.paymentMethod.toLowerCase() === 'cash' ? 1 : 0;
  const proceedsAmount = offer.price * ((100 - offer.bagentComm) / 100);
  const proceedsScore = Math.min(proceedsAmount / homePrice, 1);
  const daysUntilClosing = differenceInCalendarDays(
    new Date(offer.closingDate),
    new Date()
  );
  const closingDateScore = Math.max(1 - daysUntilClosing / 365, 0);
  const inspectionPeriodScore = offer.inspectionPeriod
    ? Math.max(1 - offer.inspectionPeriod / 30, 0)
    : 1;
  const contingentScore = offer.contingent ? 0 : 1;
  const earnestMoneyScore = Math.min(offer.earnestMoney / homePrice, 1);

  return {
    cashOffer: SCORE_WEIGHTS.CASH_OFFER * cashOfferScore,
    proceeds: SCORE_WEIGHTS.PROCEEDS * proceedsScore,
    closingDate: SCORE_WEIGHTS.CLOSING_DATE * closingDateScore,
    inspectionPeriod: SCORE_WEIGHTS.INSPECTION_PERIOD * inspectionPeriodScore,
    contingent: SCORE_WEIGHTS.CONTINGENT * contingentScore,
    earnestMoney: SCORE_WEIGHTS.EARNEST_MONEY * earnestMoneyScore,
    total:
      SCORE_WEIGHTS.CASH_OFFER * cashOfferScore +
      SCORE_WEIGHTS.PROCEEDS * proceedsScore +
      SCORE_WEIGHTS.CLOSING_DATE * closingDateScore +
      SCORE_WEIGHTS.INSPECTION_PERIOD * inspectionPeriodScore +
      SCORE_WEIGHTS.CONTINGENT * contingentScore +
      SCORE_WEIGHTS.EARNEST_MONEY * earnestMoneyScore,
  };
};

export const rankOffers = (
  offers: offers[],
  homePrice: number
): ScoredOffer[] => {
  const homepointFee = Number(import.meta.env.VITE_HOMEPOINT_FEE);
  const scoredOffers = offers.map((offer) => ({
    ...offer,
    proceeds: offer.price * ((100 - offer.bagentComm) / 100) - homepointFee,
    baseScore: calculateOfferScore(offer, homePrice).total,
  }));

  scoredOffers.sort((a, b) => b.baseScore - a.baseScore);

  return scoredOffers.map((offer, index) => ({
    ...offer,
    relativeScore: offers.length === 1 ? 1 : scoredOffers.length - index - 1,
  }));
};
