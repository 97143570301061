import { InspectionStatus } from '@prisma/client';

const getInspectionStatus = (status: InspectionStatus) => {
  switch (status) {
    case 'WAITING_QUOTE':
      return 'Awaiting Quotes';
    case 'APPROVED':
      return 'Approved';
    case 'DECLINED':
      return 'Declined';
    case 'COMPLETED':
      return 'Completed';
  }
};

export default getInspectionStatus;
