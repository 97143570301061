import { listingsWithUploads } from '../../api/services/listings';
import Image from '../../components/StreetViewImage/StreetViewImage';

const ListingImage = ({ listing }: { listing: listingsWithUploads }) => {
  const mainPhoto = listing?.uploads?.find((u) => u.main);
  return mainPhoto ? (
    <img
      src={mainPhoto.url}
      width={'100%'}
      style={{ borderRadius: 4 }}
      alt="Listing"
    />
  ) : (
    <>
      <Image address={`${listing.address} ${listing.zip}`} height={'100%'} />
    </>
  );
};

export default ListingImage;
