/* eslint-disable max-len */
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import AutoComplete from '../../pages/Listings/Autocomplete';
import vendorsService from '../../api/services/vendors';
import { MuiTelInput } from 'mui-tel-input';
import { startCase } from 'lodash';
import { InspectionType } from '@prisma/client';
import { useAuth } from '../../contexts/AuthContext';
import { AddressSelection } from '../../types/AddressSelection';

interface Props {
  onSuccess: () => void;
}

const CreateInspectionModal = NiceModal.create(({ onSuccess }: Props) => {
  const modal = useModal();
  const { currentUser } = useAuth();

  const formik = useFormik({
    initialValues: {
      name: '',
      address: null as AddressSelection | null,
      email: '',
      phone: '',
      selectedTypes: [] as InspectionType[],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      address: Yup.object().required('Address is required'),
      email: Yup.string().email('Invalid email address'),
      phone: Yup.string().required('Phone is required'),
      selectedTypes: Yup.array().min(1, 'At least one type is required'),
    }),
    onSubmit: async (values) => {
      if (!values.address) {
        return;
      }

      try {
        await vendorsService.post({
          name: values.name,
          address:
            values.address.address ??
            `${values.address.city}, ${values.address.state}`,
          lat: values.address.latitude,
          lng: values.address.longitude,
          email: values.email,
          phone: values.phone,
          type: values.selectedTypes,
          user: {
            connect: {
              id: currentUser?.id,
            },
          },
        });
        onSuccess();
        modal.remove();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      aria-labelledby="add-vendor-modal"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add Vendor</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <TextField
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <AutoComplete
              handleChange={(e) => {
                formik.setFieldValue('address', e);
              }}
              property={formik.values.address}
              allowCities={true}
              placeholder="Address or City / State"
            />
            <MuiTelInput
              label="Phone"
              value={formik.values.phone}
              onChange={(value) => formik.setFieldValue('phone', value)}
              onlyCountries={['US']}
              defaultCountry="US"
              forceCallingCode={true}
              fullWidth
            />
            <TextField
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
            <Typography variant="body2" gutterBottom>
              Services Offered
            </Typography>
            <FormGroup row>
              {Object.values(InspectionType).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={formik.values.selectedTypes.includes(type)}
                      onChange={() =>
                        formik.setFieldValue(
                          'selectedTypes',
                          formik.values.selectedTypes.includes(type)
                            ? formik.values.selectedTypes.filter(
                                (t) => t !== type
                              )
                            : [...formik.values.selectedTypes, type]
                        )
                      }
                      disabled={
                        formik.values.selectedTypes.length === 1 &&
                        formik.values.selectedTypes.includes(type)
                      }
                    />
                  }
                  label={startCase(type.toLowerCase())}
                />
              ))}
            </FormGroup>
            {formik.touched.selectedTypes && formik.errors.selectedTypes && (
              <FormHelperText error>
                {formik.errors.selectedTypes as string}
              </FormHelperText>
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modal.hide()} variant="outlined" color="error">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          Add Vendor
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default CreateInspectionModal;
