import React, { useEffect, useState } from 'react';
import {
  Stack,
  Box,
  Typography,
  Card,
  Button,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  TextField,
  Dialog,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { darken, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InspectorSearch from '/inspector_search.svg';
import useIsMobile from '../../hooks/useIsMobile';
import Footer from '../../components/Footer';
import axios from 'axios';

const RealspectorLanding = () => {
  const theme = useTheme();
  const mobile = useIsMobile();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleWaitlistSubmission = async () => {
    if (!email) return;

    setIsSubmitting(true);
    try {
      await axios.post('https://api.getwaitlist.com/api/v1/signup', {
        email,
        waitlist_id: 23032, // from your URL: getwaitlist.com/waitlist/23032
      });

      // Clear email and show success
      setEmail('');
      alert('Thanks for joining the waitlist!');
    } catch (error) {
      console.error('Waitlist submission failed:', error);
      alert('Failed to join waitlist. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setDialogOpen(true);
  };

  return (
    <div
      style={{
        backgroundColor: '#f6f9fb',
      }}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 10 }}>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <Stack
              direction={'column'}
              textAlign={mobile ? 'center' : undefined}
            >
              <span>
                <Box mb={2}>
                  <Typography variant={mobile ? 'h3' : 'h2'} fontWeight={700}>
                    Home Inspections, Simplified.
                  </Typography>
                  <Typography variant="h6" fontSize={mobile ? 20 : undefined}>
                    Automated. Efficient.&nbsp;
                    <span
                      style={{
                        textDecoration: 'underline',
                        textDecorationColor: theme.palette.primary.main,
                        textDecorationThickness: 4,
                      }}
                    >
                      Hassle-free.
                    </span>
                  </Typography>
                </Box>
                <Card sx={{ p: 2 }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="column">
                      <TextField
                        label="Email"
                        InputProps={{
                          style: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          },
                        }}
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleWaitlistSubmission();
                          }
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={isSubmitting}
                        sx={{
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          textWrap: 'nowrap',
                          minWidth: 130,
                        }}
                        onClick={handleWaitlistSubmission}
                      >
                        {isSubmitting ? 'Submitting...' : 'Start Free Trial'}
                      </Button>
                    </Stack>
                    <Typography variant="body1" fontWeight={600}>
                      Let Realspector handle your inspection scheduling and
                      coordination
                    </Typography>
                    {/* ... mobile button remains the same ... */}
                  </Stack>
                </Card>
              </span>
            </Stack>
          </Grid>
          <Grid xs={12} sm={6}>
            <img
              src={InspectorSearch}
              style={{
                width: '100%',
                borderRadius: 8,
                maxHeight: 500,
              }}
            />
          </Grid>
        </Grid>

        <Container maxWidth="lg">
          <Stack direction="column" mt={mobile ? 0 : 4} spacing={2}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700 }}
              textAlign={'center'}
            >
              About Realspector
            </Typography>
            <Typography>
              Realspector is an AI-powered inspection coordination tool that
              revolutionizes how real estate professionals handle home
              inspections. Our platform automatically connects with local
              inspectors to manage quotes, scheduling, and handles all the
              coordination - saving you hours of back-and-forth communication.
            </Typography>
            <Typography>
              With Realspector, you can focus on what matters most - your
              clients. Our AI assistant handles everything from finding
              available inspectors to collecting quotes and coordinating
              schedules, all while keeping everyone informed throughout the
              process.
            </Typography>
          </Stack>
        </Container>

        <Divider sx={{ opacity: 0.5, margin: 4 }} />
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            sx={{ fontWeight: 700, mb: 3 }}
            textAlign="center"
          >
            How It Works
          </Typography>
          <Grid container spacing={3}>
            <Grid xs={12} md={6}>
              <Stack spacing={2}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={600}>
                      1. Submit Your Request
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Simply text or email us the offer letter, and our AI will
                      automatically extract the inspection window.
                      Alternatively, you can manually submit a request or set up
                      CRM integration to automatically initiate the process.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={600}>
                      2. Quote Collection
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Our system automatically sends quote requests to your
                      preferred vendors first, followed by other qualified
                      inspection vendors in your area. You can easily maintain a
                      list of trusted inspectors who will receive priority for
                      your requests. Each vendor can submit their availability
                      and pricing for the job for your clients to review.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={600}>3. Quote Selection</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Your clients can easily review and compare submitted
                      quotes side-by-side, choosing the inspector that best fits
                      their needs based on availability, pricing, and services
                      offered. Once they approve a quote, the inspection is
                      automatically scheduled.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={600}>
                      4. Automated Coordination
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      After selection, our system handles all communication
                      between parties, sends reminders, and ensures everyone
                      stays informed throughout the inspection process.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Grid>
            <Grid xs={12} md={6} display="flex" alignItems="center">
              <Card sx={{ p: 3, width: '100%' }}>
                <Stack spacing={2}>
                  <Typography variant="h6" fontWeight={600}>
                    Key Features
                  </Typography>
                  <Typography>
                    • Automatic extraction from offer letters
                  </Typography>
                  <Typography>
                    • Multi-vendor quote requests for your clients
                  </Typography>
                  <Typography>
                    • Client-friendly quote comparison interface
                  </Typography>
                  <Typography>• Automated vendor communication</Typography>
                  <Typography>• CRM integration available</Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Divider sx={{ opacity: 0.5, margin: 4 }} />
        <Grid container spacing={2} mt={2} justifyContent={'center'}>
          <Grid xs={12} md={4}>
            <img
              src={'/inspections_quote_approve.png'}
              style={{
                width: '100%',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 8,
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick('/inspections_quote_approve.png')}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <img
              src={'/inspections_quote_confirmed.png'}
              style={{
                width: '100%',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 8,
                cursor: 'pointer',
              }}
              onClick={() =>
                handleImageClick('/inspections_quote_confirmed.png')
              }
            />
          </Grid>
          <Grid xs={12} md={4}>
            <img
              src={'/inspections_screen.png'}
              style={{
                width: '100%',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 8,
                cursor: 'pointer',
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ opacity: 0.5, margin: 4 }} />
        <Container maxWidth="lg">
          <Stack direction="column" alignItems={'center'} mt={4}>
            <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 2 }}>
              Pricing
            </Typography>
            <Typography fontSize={32} align="center">
              30 days free trial
              <br />
              <b>$20</b> per month
            </Typography>
          </Stack>
        </Container>
        <Divider sx={{ opacity: 0.5, margin: 4 }} />
        <Stack direction="column" alignItems="center">
          <TextField
            label="Email"
            InputProps={{
              style: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: '#fff',
              },
            }}
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleWaitlistSubmission();
              }
            }}
            sx={{ maxWidth: 400 }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={isSubmitting}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              textWrap: 'nowrap',
              minWidth: 130,
              maxWidth: 400,
              width: '100%',
            }}
            onClick={handleWaitlistSubmission}
          >
            {isSubmitting ? 'Submitting...' : 'Get Started'}
          </Button>
        </Stack>
      </Container>
      <Footer />
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <img
          src={selectedImage}
          style={{
            width: '100%',
            height: 'auto',
            cursor: 'pointer',
          }}
          onClick={() => setDialogOpen(false)}
        />
      </Dialog>
    </div>
  );
};

export default RealspectorLanding;
