import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { listingsWithUploads } from '../../api/services/listings';
import MyListing from '../../pages/Dashboard/Selling/Listing/MyListing';
import AdjustPriceModal from '../AdjustPriceModal/AdjustPriceModal';
import NiceModal from '@ebay/nice-modal-react';
import ListingImage from '../ListingImage/ListingImage';

interface ListingCardProps {
  listing: listingsWithUploads;
  slim?: boolean;
  showEditButton?: boolean;
  showViewButton?: boolean;
  showAdjustPriceButton?: boolean;
}

const ListingCard: React.FC<ListingCardProps> = ({
  listing,
  slim = false,
  showEditButton = true,
  showViewButton = true,
  showAdjustPriceButton = true,
}) => {
  const navigate = useNavigate();

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} sm={2}>
        <ListingImage listing={listing} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <MyListing listing={listing} slim={slim} />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={2}
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        {listing.status !== 'APPROVED' && showEditButton && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate(`/listings/${listing?.id}/edit`)}
              fullWidth
            >
              Edit
            </Button>
          </Grid>
        )}
        {showViewButton && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate(`/listings/${listing?.id}`)}
              fullWidth
              disabled={listing.status !== 'APPROVED'}
            >
              View
            </Button>
          </Grid>
        )}
        {listing.status === 'APPROVED' && showAdjustPriceButton && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => NiceModal.show(AdjustPriceModal, { listing })}
              fullWidth
            >
              Adjust Price
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ListingCard;
