import React, { memo, useMemo } from 'react';
import { TextField, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const combineDateTime = (date: Date, timeStr: string): Date => {
  // Parse the time string
  const [time, modifier] = timeStr.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;

  // Combine the date and time
  const combinedDate = new Date(date);
  combinedDate.setHours(hours, minutes, 0, 0);

  return combinedDate;
};

const formatTime = (date: Date | string): string => {
  const d = new Date(date);
  // Round minutes to nearest 15
  const minutes = d.getMinutes();
  const roundedMinutes = Math.round(minutes / 15) * 15;

  // Create new date with rounded minutes
  const roundedDate = new Date(d);
  roundedDate.setMinutes(roundedMinutes);

  // If we rounded up to 60, we need to increment the hour
  if (roundedMinutes === 60) {
    roundedDate.setHours(roundedDate.getHours() + 1);
    roundedDate.setMinutes(0);
  }

  return roundedDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
};

interface Props {
  value: Date | null;
  onChange: (newDateTime: Date | null) => void;
  showLabel?: boolean;
  showTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
}

const DateTimeSelector: React.FC<Props> = ({
  value,
  onChange,
  showLabel = true,
  showTime = true,
  minDate,
  maxDate,
  label,
}) => {
  const date = value || new Date();
  const time = value ? formatTime(value) : '12:00 PM';
  const times = useMemo(
    () => [
      '7:00 AM',
      '7:15 AM',
      '7:30 AM',
      '7:45 AM',
      '8:00 AM',
      '8:15 AM',
      '8:30 AM',
      '8:45 AM',
      '9:00 AM',
      '9:15 AM',
      '9:30 AM',
      '9:45 AM',
      '10:00 AM',
      '10:15 AM',
      '10:30 AM',
      '10:45 AM',
      '11:00 AM',
      '11:15 AM',
      '11:30 AM',
      '11:45 AM',
      '12:00 PM',
      '12:15 PM',
      '12:30 PM',
      '12:45 PM',
      '1:00 PM',
      '1:15 PM',
      '1:30 PM',
      '1:45 PM',
      '2:00 PM',
      '2:15 PM',
      '2:30 PM',
      '2:45 PM',
      '3:00 PM',
      '3:15 PM',
      '3:30 PM',
      '3:45 PM',
      '4:00 PM',
      '4:15 PM',
      '4:30 PM',
      '4:45 PM',
      '5:00 PM',
      '5:15 PM',
      '5:30 PM',
      '5:45 PM',
      '6:00 PM',
      '6:15 PM',
      '6:30 PM',
      '6:45 PM',
      '7:00 PM',
    ],
    []
  );

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      const newDateTime = combineDateTime(newDate, time);
      onChange(newDateTime);
    } else {
      onChange(null);
    }
  };

  const handleTimeChange = (newTime: string) => {
    const newDateTime = combineDateTime(date, newTime);
    onChange(newDateTime);
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid xs={12}>
        {showLabel && (
          <Typography fontWeight={500}>Select Date and Time</Typography>
        )}
      </Grid>

      <Grid xs={showTime ? 6 : 12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={label ?? 'Select Date'}
            value={date}
            onChange={handleDateChange}
            minDate={minDate || new Date()}
            maxDate={maxDate}
            sx={{ width: '100%' }}
          />
        </LocalizationProvider>
      </Grid>
      {showTime && (
        <Grid xs={6}>
          <TextField
            select
            label="Select Time"
            value={time}
            onChange={(e) => handleTimeChange(e.target.value)}
            fullWidth
          >
            {times.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(DateTimeSelector);
