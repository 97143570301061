import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectNotes = (state: RootState) =>
  state.notifications.notifications;

export const selectNotesForToolbar = createSelector([selectNotes], (notes) => {
  return notes.filter(
    (note) =>
      note.resourceType === 'showing' ||
      note.resourceType === 'offer' ||
      note.resourceType === 'messages'
  );
});
