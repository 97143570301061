/* eslint-disable max-len */
import { Typography, Card, CardContent, Button } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
// eslint-disable-next-line max-len
import StripeCheckout from '../Dashboard/Buying/ScheduleTourModal/StripeCheckout';
import { quotes } from '@prisma/client';
import quotesService from '../../api/services/quotes';
import { useSnackbar } from 'notistack';

interface QuoteStripeProps {
  clientSecret: string;
  quote: quotes;
  onSuccess: () => void;
}

const QuoteStripe = ({ clientSecret, quote, onSuccess }: QuoteStripeProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const snackbar = useSnackbar();

  const submitPayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'http://localhost:5137/complete',
      },
      redirect: 'if_required',
    });

    if (error) {
      console.error(error);
      return;
    }

    await quotesService.patch(quote.id, {
      paid: true,
    });
    onSuccess();
    snackbar.enqueueSnackbar(
      'Referral fee paid successfully. You can now view the job details and contact the buyer.',
      {
        variant: 'success',
      }
    );
  };

  return (
    <>
      <Typography variant="h6">Quote Approved</Typography>
      <Card>
        <CardContent>
          <Typography variant="body2">
            Your quote has been approved. Please pay the <b>$10 referral fee</b>{' '}
            to access job details.
          </Typography>
          <br />
          {clientSecret && (
            <>
              <StripeCheckout />
              <br />
              <Button
                variant="contained"
                fullWidth
                color="success"
                onClick={submitPayment}
              >
                Pay $10
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default QuoteStripe;
