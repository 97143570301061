import { useEffect, useState } from 'react';
import closingCostsService from '../../api/services/closingCosts';
import { Skeleton, Stack, Typography } from '@mui/material';
import { formatCurrency } from '../../utils/formatCurrency';
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip';
import { InfoOutlined } from '@mui/icons-material';

interface Props {
  listingId: string;
  offerId: string;
}

interface ClosingCosts {
  costs: number;
  attorneyFee: number;
  bac: number;
  brokerFee: number;
  miscFee: number;
  mortgageBalance: number;
  proceeds: number;
  taxFee: number;
  titleFee: number;
  termiteFee: number;
}

const Proceeds: React.FC<Props> = ({ listingId, offerId }) => {
  const [costs, setCosts] = useState<ClosingCosts | null>(null);

  const fetchClosingCost = async () => {
    const costs = await closingCostsService.find(listingId, offerId);
    setCosts(costs);
  };

  useEffect(() => {
    fetchClosingCost();
  }, []);

  const tooltipContent = (
    <Stack spacing={1}>
      <Typography variant="body2">
        Termite Fee: {formatCurrency(costs?.termiteFee ?? 0)}
      </Typography>
      <Typography variant="body2">
        Misc Fee: {formatCurrency(costs?.miscFee ?? 0)}
      </Typography>
      <Typography variant="body2">
        Title Fee: {formatCurrency(costs?.titleFee ?? 0)}
      </Typography>
      <Typography variant="body2">
        Taxes: {formatCurrency(costs?.taxFee ?? 0)}
      </Typography>
      <Typography variant="body2">
        Buyer&apos;s Agent Commission: {formatCurrency(costs?.bac ?? 0)}
      </Typography>
      <Typography variant="body2">
        Attorney Fee: {formatCurrency(costs?.attorneyFee ?? 0)}
      </Typography>
      <Typography variant="body2">
        Broker Fee: {formatCurrency(costs?.brokerFee ?? 0)}
      </Typography>
      <Typography variant="body2" fontWeight={600}>
        Est. Closing Costs: {formatCurrency(costs?.costs ?? 0)}
      </Typography>
      <Typography variant="body2" fontWeight={600}>
        Mortgage Balance: {formatCurrency(costs?.mortgageBalance ?? 0)}
      </Typography>
    </Stack>
  );

  if (!costs) {
    return <Skeleton />;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body2" fontWeight={600}>
        {formatCurrency(costs.proceeds)}
      </Typography>
      <CustomTooltip title={tooltipContent}>
        <InfoOutlined />
      </CustomTooltip>
    </Stack>
  );
};

export default Proceeds;
