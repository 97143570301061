import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import {
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { fetchListings } from '../../../store/slices/listings';
import { selectBuyers } from '../../../store/selectors/buyers';
import { selectSellers } from '../../../store/selectors/sellers';

const Clients = () => {
  const buyers = useSelector((state: RootState) => selectBuyers(state));
  const sellers = useSelector((state: RootState) => selectSellers(state));
  const dispatch = useAppDispatch();
  const listings = useSelector((state: RootState) => state.listings.listings);
  const clients = [...buyers, ...sellers];

  useEffect(() => {
    dispatch(fetchListings({}));
  }, [dispatch]);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">Clients</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Selling</TableCell>
              <TableCell>Buying</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>
                  {client.firstName} {client.lastName}
                </TableCell>
                <TableCell>
                  {
                    listings.filter(
                      (listing) =>
                        listing.user.id === client.id &&
                        listing.type === 'BUYER'
                    ).length
                  }
                </TableCell>
                <TableCell>
                  {
                    listings.filter(
                      (listing) =>
                        listing.user.id === client.id &&
                        listing.type === 'SELLER'
                    ).length
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Clients;
