import { Home } from '@mui/icons-material';
import {
  Typography,
  Stack,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import FormSVG from '/form_input.svg';
import ForSale from '/for_sale.svg';
import Review from '/review.svg';
import ClosingSVG from '/closing.svg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const HIWSelling: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography
        variant="h4"
        textAlign={'center'}
        sx={{ fontWeight: 700, mt: 4 }}
      >
        Selling Your Home Has Never Been Easier
      </Typography>
      {/* <Typography variant="subtitle2" textAlign={'center'}>
        The experience of a traditional agent for a flat fee.
      </Typography> */}
      <Typography variant="subtitle1" textAlign={'center'}>
        Get support from a real estate agent at any time.
      </Typography>
      <Grid2 container>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 1</Typography>
            <Typography variant="h5" fontWeight={600}>
              Create Your Listing
            </Typography>
            Sign up and follow our simple step-by-step guide to create a
            professional listing.
          </Stack>
          <Typography>
            <ul>
              <li>
                Get your home ready for market with our expert tips and
                resources
              </li>
              <li>Get expert pricing advice</li>
              <li>Professional photos & floorplans</li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={FormSVG} height={350} />
            </Grid2>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={ForSale} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 2</Typography>
            <Typography variant="h5" fontWeight={600}>
              We Market Your Home
            </Typography>
            Your home gets maximum exposure with marketing across multiple
            platforms.
          </Stack>
          <Typography>
            <ul>
              <li>MLS listing</li>
              <li>Social media advertising</li>
              <li>Yard sign and online promotions</li>
            </ul>
          </Typography>
        </Grid2>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 3</Typography>
            <Typography variant="h5" fontWeight={600}>
              Receive and Compare Offers
            </Typography>
            Easily review and respond to offers right from your personal
            dashboard.
          </Stack>
          <Typography>
            <ul>
              <li>Compare offers side by side</li>
              <li>Get notified and review new offers instantly</li>
              <li>Negotiate with confidence</li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={mobile ? 0 : 6} textAlign={'center'}>
              <img src={Review} height={350} />
            </Grid2>
            <Grid2 xs={mobile ? 0 : 6} textAlign={'center'}>
              <img src={ClosingSVG} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 4</Typography>
            <Typography variant="h5" fontWeight={600}>
              Close the Sale
            </Typography>
            We&apos;ll guide you through the closing process, ensuring
            everything runs smoothly.
            <ul>
              <li>All necessary paperwork handled</li>
              <li>Title and closing services coordinated</li>
              <li>Celebrate your home sale!</li>
            </ul>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

export default HIWSelling;
