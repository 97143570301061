import { CheckBoxRounded, Check, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Skeleton,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatCurrency } from '../../../utils/formatCurrency';
import HowItWorksBuying from './HIWBuying';
import { useEffect, useState } from 'react';
import ratesService from '../../../api/services/rates';
import { CustomTooltip } from '../../../components/CustomTooltip/CustomTooltip';

interface Props {
  mobile: boolean;
  price: number;
  handlePriceChange: (price: number) => void;
}

function calculateMortgageSavings(
  originalMortgageAmount: number,
  reducedMortgageAmount: number,
  interestRate: number,
  loanTermYears: number
): number {
  const monthlyInterestRate = interestRate / 100 / 12; // Convert annual rate to monthly
  const numberOfPayments = loanTermYears * 12; // Total number of payments (months)

  const calculateMonthlyPayment = (principal: number): number => {
    return (
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1)
    );
  };

  const originalMonthlyPayment = calculateMonthlyPayment(
    originalMortgageAmount
  );
  const reducedMonthlyPayment = calculateMonthlyPayment(reducedMortgageAmount);

  const totalPaymentOriginal = originalMonthlyPayment * numberOfPayments;
  const totalPaymentReduced = reducedMonthlyPayment * numberOfPayments;

  const savings = totalPaymentOriginal - totalPaymentReduced;

  return savings;
}

const Buyer: React.FC<Props> = ({ mobile, price, handlePriceChange }) => {
  const traditionalFee = price * 0.025;
  const homepointFee = Number(import.meta.env.VITE_HOMEPOINT_FEE);
  const savings = traditionalFee - homepointFee;
  const [rate, setRate] = useState<number | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const getRates = async () => {
    const rates = await ratesService.find();
    const rate = rates.find((rate) => rate.term === '30 year');
    if (rate) {
      setRate(rate.rate);
    } else {
      setRate(6);
    }
  };

  useEffect(() => {
    getRates();
  }, []);

  return (
    <Stack direction="column" spacing={0}>
      <Typography
        variant="h4"
        textAlign={'center'}
        sx={{ fontWeight: 700 }}
        pt={mobile ? 2 : 0}
      >
        The Homepoint Difference
      </Typography>
      <Typography variant="subtitle2" textAlign={'center'}>
        Not working with an agent? Try Homepoint.
      </Typography>
      <Stack
        direction="column"
        alignItems={'center'}
        pt={2}
        pb={mobile ? 6 : 0}
        sx={{ px: 2 }}
      >
        <Typography variant="subtitle2">
          Savings based on a <b>${price / 1000}k</b> home sale
        </Typography>
        <Slider
          min={250000}
          max={1000000}
          value={price}
          step={50000}
          onChange={(event: any) =>
            handlePriceChange(
              event.target?.value === '' ? 0 : Number(event.target?.value)
            )
          }
          sx={{ width: '100%', maxWidth: 500 }}
        />
      </Stack>
      <Box sx={{ width: '100%', mt: mobile ? -3 : 3 }}>
        <TableContainer
          sx={{
            borderRadius: 2,
            border: '1px solid #ccc',
          }}
        >
          <Table
            sx={{
              borderCollapse: 'collapse',
              borderStyle: 'hidden',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Homepoint
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Traditional Agent</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <Stack direction="row" alignItems="center">
                    <b>Buying Power</b>
                    <CustomTooltip
                      title={
                        <>
                          <Typography variant="body2">
                            Make your offer more competitive, apply to your
                            closing costs, or reduce the home price. You choose
                            how to use your buying power.
                          </Typography>
                        </>
                      }
                      open={showTooltip}
                      onOpen={() => setShowTooltip(true)}
                      onClose={() => setShowTooltip(false)}
                    >
                      <IconButton>
                        <InfoOutlined
                          onClick={() => setShowTooltip(!showTooltip)}
                        />
                      </IconButton>
                    </CustomTooltip>
                  </Stack>
                  <Typography variant="caption">Savings</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    color="success.main"
                    fontWeight={700}
                  >
                    {formatCurrency(savings)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={700}>
                    {formatCurrency(0)}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>
                  <b>Potential Mortgage Savings</b>
                  <br />
                  <Typography variant="caption">
                    Over 30 years at avg rates
                  </Typography>
                </TableCell>
                <TableCell>
                  {rate ? (
                    <Typography
                      variant="body1"
                      color="success.main"
                      fontWeight={700}
                    >
                      {formatCurrency(
                        calculateMortgageSavings(
                          price,
                          price - savings,
                          rate,
                          30
                        )
                      )}
                    </Typography>
                  ) : (
                    <Skeleton />
                  )}
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={700}>
                    {formatCurrency(0)}
                  </Typography>
                </TableCell>
              </TableRow> */}
              {/* <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <b>
                    Tour Homes For Sale
                    <br />
                    by Homepoint
                  </b>
                </TableCell>
                <TableCell>
                  <Typography color="success.main">
                    <b>Free</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>
                  <b>Tour Any Home</b>
                </TableCell>
                <TableCell>
                  $50 / showing
                  <br />
                  <Typography variant="caption">
                    Refunded if you buy a home
                  </Typography>
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <b>Offers</b>
                </TableCell>
                <TableCell>
                  <CheckBoxRounded color="primary" />
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>
                    Transaction
                    <br />
                    Coordination
                  </b>
                </TableCell>
                <TableCell>
                  <Stack direction={mobile ? 'column' : 'row'}>
                    <CheckBoxRounded color="primary" />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <b>Cost</b>
                  <br />
                  <Typography variant="caption">Paid at closing</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    color="success.main"
                    fontWeight={700}
                  >
                    {formatCurrency(homepointFee)}
                  </Typography>
                  <Typography variant="caption">Homepoint Fee</Typography>
                </TableCell>
                <TableCell>
                  {formatCurrency(traditionalFee)}
                  <br />
                  <Typography variant="caption">
                    3% Buyer&apos;s Agent Commission
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div id="hiw" />
      <HowItWorksBuying />
    </Stack>
  );
};

export default Buyer;
