import React, { useState, useEffect } from 'react';
import {
  TextField,
  Typography,
  Box,
  Stack,
  Container,
  Card,
  Alert,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usersService from '../api/services/users';
import { Link, useSearchParams } from 'react-router-dom';
import { Prisma, UserType } from '@prisma/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { H } from 'highlight.run';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSnackbar } from 'notistack';
import useIsMobile from '../hooks/useIsMobile';

// Validation schema using Yup
const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .test('is-valid-tel', 'Phone number is invalid', (value) =>
      matchIsValidTel(value)
    ),
});

const Success: React.FC<{ type: UserType }> = ({ type }) => {
  return (
    <Stack direction="column" alignItems={'center'} spacing={1}>
      <Link to="/">
        <img src="/new_logo_light.png" width={'100%'} />
      </Link>
      <Typography variant="h5">Thank you!</Typography>
      <Typography variant="body1" textAlign={'center'}>
        A verification email has been sent to your email address. You may click
        the button below to log in.
      </Typography>
      <Button variant="contained" fullWidth href="/login">
        Log In
      </Button>
    </Stack>
  );
};

const SignUp = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const mobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('id');
  const address = searchParams.get('address');
  const email = searchParams.get('email');
  const type = searchParams.get('type') as UserType | null;
  const snackbar = useSnackbar();

  // Retrieve form data from local storage
  const initialFormValues = JSON.parse(
    localStorage.getItem('signupForm') || '{}'
  );

  // Add recaptcha ref
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const postUser = async (user: Prisma.usersCreateInput) => {
    try {
      setLoading(true);
      // Execute recaptcha before posting
      const token = await recaptchaRef.current?.executeAsync();
      if (!token) {
        setLoading(false);
        return;
      }

      await usersService.post({
        ...user,
        type: 'SELLER',
        listingId: listingId ?? undefined,
      });
      setSuccess(true);
      setLoading(false);
      H.track('Signed Up');
      localStorage.removeItem('signupForm');
    } catch (e) {
      setLoading(false);
      snackbar.enqueueSnackbar(
        // eslint-disable-next-line max-len
        'Unable to sign up. You may already have an account. Please check your email for a verification link.',
        { variant: 'error' }
      );
      console.error(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: initialFormValues.firstName || '',
      lastName: initialFormValues.lastName || '',
      email: initialFormValues.email || email || '',
      password: '',
      confirmPassword: '',
      type: type ?? 'SELLER',
      phone: initialFormValues.phone || '',
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      const { confirmPassword, ...rest } = values;
      postUser(rest);
    },
  });

  // Save form data to local storage on change
  useEffect(() => {
    localStorage.setItem('signupForm', JSON.stringify(formik.values));
  }, [formik.values]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw"
      sx={{
        backgroundImage: 'url("/houses/house4_small.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ padding: 4, m: mobile ? 0 : 4 }}>
          {success ? (
            <Success type={type ?? 'SELLER'} />
          ) : (
            <Stack direction={'column'} spacing={2}>
              <Link to="/">
                <img
                  src="/new_logo_light.png"
                  style={{
                    maxHeight: 75,
                    width: '100%',
                    display: 'block',
                    margin: '0 auto',
                  }}
                />
              </Link>
              <span>
                <Typography variant="h4" sx={{ fontWeight: 700 }} fontSize={32}>
                  Sign Up Here
                </Typography>
                <Typography variant="body2">
                  Please enter your details.
                </Typography>
              </span>
              {address && (
                <Alert severity="info">{decodeURIComponent(address)}</Alert>
              )}
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  sx={{ mb: 2 }}
                  {...formik.getFieldProps('firstName')}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                      ? (formik.errors.firstName as string)
                      : ''
                  }
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  sx={{ mb: 2 }}
                  {...formik.getFieldProps('lastName')}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={
                    formik.touched.lastName && formik.errors.lastName
                      ? String(formik.errors.lastName)
                      : ''
                  }
                />
                <MuiTelInput
                  value={formik.values.phone}
                  onChange={(value) => formik.setFieldValue('phone', value)}
                  onlyCountries={['US']}
                  defaultCountry="US"
                  fullWidth
                  sx={{ mb: 2 }}
                  label="Phone Number"
                  forceCallingCode={true}
                  helperText={
                    formik.touched.phone && formik.errors.phone
                      ? String(formik.errors.phone)
                      : ''
                  }
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  sx={{ mb: 2, minWidth: 200 }}
                  {...formik.getFieldProps('email')}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? String(formik.errors.email)
                      : ''
                  }
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  sx={{ mb: 2 }}
                  {...formik.getFieldProps('password')}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  sx={{ mb: 2 }}
                  {...formik.getFieldProps('confirmPassword')}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY || ''}
                  size="invisible"
                  style={{ visibility: 'hidden' }}
                />
                <LoadingButton
                  variant="contained"
                  sx={{ mb: 2, backgroundColor: 'primary' }}
                  fullWidth
                  type="submit"
                  loading={loading}
                >
                  Sign Up
                </LoadingButton>
                <Typography variant="caption">
                  By clicking sign up, you confirm that you have read and agree
                  to our <Link to={'/policies'}>policies</Link>.
                </Typography>
                <br />
                <Typography variant="caption" fontSize={10}>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{' '}
                  apply.
                </Typography>
              </form>
              {/* <Typography variant="subtitle2" align="center">
                Already have an account?{" "}
                <Link color="secondary" href="/login" underline="always">
                  Login
                </Link>
              </Typography> */}
            </Stack>
          )}
        </Card>
      </Container>
    </Box>
  );
};

export default SignUp;
