import { useSelector } from 'react-redux';
import { fetchVendors } from '../../store/slices/vendors';
import { RootState, useAppDispatch } from '../../store/store';
import { useEffect } from 'react';
import {
  Stack,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  Typography,
  TableBody,
  Button,
  Skeleton,
  Paper,
} from '@mui/material';
import { InspectionType, vendors } from '@prisma/client';
import NiceModal from '@ebay/nice-modal-react';
import { startCase } from 'lodash';
import AddVendorModal from '../../components/AddVendorModal/AddVendorModal';
import parsePhoneNumber from 'libphonenumber-js';

const Vendors = () => {
  const { vendors, loading, error } = useSelector(
    (state: RootState) => state.vendors
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVendors());
  }, [dispatch]);

  if (loading) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Preferred Vendors</Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="highlight"
            onClick={() =>
              NiceModal.show(AddVendorModal, {
                onSuccess: () => dispatch(fetchVendors()),
              })
            }
          >
            Add Preferred Vendor
          </Button>
        </Stack>
      </Stack>
      {vendors.length === 0 ? (
        <Paper sx={{ p: 3 }}>
          <Typography align="center" color="text.secondary">
            No preferred vendors have been added yet.
          </Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Services Offered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendors.map((vendor: vendors) => (
                <TableRow key={vendor.id}>
                  <TableCell>{vendor.name}</TableCell>
                  <TableCell>{vendor.email}</TableCell>
                  <TableCell>
                    {parsePhoneNumber(vendor.phone)?.formatNational()}
                  </TableCell>
                  <TableCell>{vendor.address}</TableCell>
                  <TableCell>
                    {vendor.type
                      .map((type: InspectionType) =>
                        startCase(type.toLowerCase())
                      )
                      .join(', ')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default Vendors;
