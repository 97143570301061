import { AxiosResponse } from 'axios';
import axiosInstance from '../api';
import { inspections, Prisma } from '@prisma/client';

export type InspectionsWithRelations = Prisma.inspectionsGetPayload<{
  include: {
    vendors: true;
    transaction: {
      include: {
        listings: true;
      };
    };
  };
}>;

const inspectionsService = {
  find: async (params: Prisma.inspectionsFindManyArgs) => {
    const res: AxiosResponse<InspectionsWithRelations[]> =
      await axiosInstance.get('/inspections', { params });
    return res.data;
  },
  get: async (id: string, token?: string) => {
    const res: AxiosResponse<InspectionsWithRelations> =
      await axiosInstance.get(`/inspections/${id}`, {
        params: {
          token,
        },
      });
    return res.data;
  },
  create: async (
    data: Omit<Prisma.inspectionsCreateInput, 'token'> & {
      lat: number;
      lng: number;
    }
  ) => {
    const res: AxiosResponse<inspections> = await axiosInstance.post(
      '/inspections',
      data
    );
    return res.data;
  },
  update: async (
    id: string,
    data: Prisma.inspectionsUpdateInput,
    token?: string
  ) => {
    const res: AxiosResponse<inspections> = await axiosInstance.patch(
      `/inspections/${id}`,
      data,
      {
        params: {
          token,
        },
      }
    );
    return res.data;
  },
};

export default inspectionsService;
