import { lazy } from 'react';
import { IRoute } from '../interfaces';
import {
  AssignmentOutlined,
  AttachMoney,
  HandshakeOutlined,
  HomeOutlined,
  Link,
  ListAlt,
  ListAltOutlined,
  PeopleOutline,
  PriceChangeOutlined,
  PriceCheckOutlined,
  SearchOutlined,
  SellOutlined,
  Settings,
  SpaceDashboard,
} from '@mui/icons-material';
import Offers from '../pages/Offers/Offers';
import SettingsPage from '../pages/Settings/Settings';
import AdminShowings from '../pages/Admin/Showings/Showings';
import { ClockIcon } from '@mui/x-date-pickers';
import AdminOffers from '../pages/Admin/Offers/Offers';
import Comps from '../pages/Admin/Comps/Comps';
import PriceOpinion from '../pages/PriceOpinion/PriceOpinion';
import Listings from '../pages/Admin/Listings/Listings';
import ShortURLRedirect from '../components/ShortURLRedirect/ShortURLRedirect';
import TransactionDashboard from '../pages/Transactions/TransactionDashboard';
import ListingBySlug from '../pages/Listings/ListingBySlug';
import Vendors from '../pages/Vendors/Vendors';
import Clients from '../pages/Admin/Clients/Clients';
import Quote from '../pages/Vendors/Quote';
import Inspections from '../pages/Inspections/Inspections';
import InspectionsDetail from '../pages/Inspections/InspectionsDetail';
import ShowingSurvey from '../pages/ShowingSurvey/ShowingSurvey';
import QRCode from '../pages/QR/QRCode';

// Lazy load the components
const SellingDasboard = lazy(
  () => import('../pages/Dashboard/Selling/SellerDashboard')
);
const BuyingDashboard = lazy(
  () => import('../pages/Dashboard/Buying/BuyerDashboard')
);
const CreateListing = lazy(() => import('../pages/EditListing/EditListing'));
const Listing = lazy(() => import('../pages/Listings/Listing'));
const Detail = lazy(
  () => import('../pages/Dashboard/Selling/Buyers/Detail/Detail')
);

export const routes: Array<IRoute> = [
  {
    key: 'selling-route',
    title: 'Selling',
    path: '/selling',
    enabled: true,
    component: SellingDasboard,
    icon: HomeOutlined,
    menu: true,
    protected: true,
    sellerOnly: true,
  },
  {
    key: 'buying-route',
    title: 'Buying',
    path: '/buying',
    enabled: true,
    component: BuyingDashboard,
    icon: SearchOutlined,
    menu: true,
    protected: true,
    sellerOnly: true,
  },
  {
    key: 'edit-listing-route',
    title: 'Edit Listing',
    path: '/listings/:id/edit',
    enabled: true,
    component: CreateListing,
    icon: SpaceDashboard,
    menu: false,
    protected: true,
    sellerOnly: true,
  },
  {
    key: 'buyer-detail-route',
    title: '',
    path: '/buyers/:id',
    enabled: true,
    component: Detail,
    icon: ListAlt,
    menu: false,
    protected: true,
    sellerOnly: false,
  },
  {
    key: 'listing-route',
    title: 'Listing',
    path: '/listings/:id',
    enabled: true,
    component: Listing,
    icon: ListAlt,
    menu: false,
    protected: false,
  },
  {
    key: 'offers-route',
    title: 'Offers',
    path: '/offers',
    enabled: true,
    component: Offers,
    icon: SellOutlined,
    menu: true,
    protected: true,
    sellerOnly: true,
  },
  {
    key: 'showings-admin',
    title: 'Showings',
    path: '/admin/showings',
    enabled: true,
    component: AdminShowings,
    icon: ClockIcon,
    menu: true,
    protected: true,
    adminOnly: true,
    accountType: 'PREMIUM',
  },
  {
    key: 'offers-admin',
    title: 'Offers',
    path: '/admin/offers',
    enabled: true,
    component: AdminOffers,
    icon: AttachMoney,
    menu: true,
    protected: true,
    adminOnly: true,
    accountType: 'PREMIUM',
  },
  {
    key: 'inspections-detail-route',
    title: 'Inspection',
    path: '/inspections/:id',
    enabled: true,
    component: InspectionsDetail,
    icon: PriceCheckOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'quote-route',
    title: 'Quote',
    path: '/q/:id',
    enabled: true,
    component: Quote,
    icon: PriceCheckOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'comps-admin',
    title: 'Comps',
    path: '/admin/comps',
    enabled: true,
    component: Comps,
    icon: PriceCheckOutlined,
    menu: true,
    protected: true,
    adminOnly: true,
    accountType: 'PREMIUM',
  },
  {
    key: 'price-opinion-route',
    title: 'Price Opinion',
    path: '/price-opinion/:id',
    icon: PriceChangeOutlined,
    enabled: true,
    component: PriceOpinion,
    menu: false,
    protected: true,
    sellerOnly: true,
  },
  {
    key: 'listings-admin',
    title: 'Listings',
    path: '/admin/listings',
    enabled: true,
    component: Listings,
    icon: ListAltOutlined,
    menu: true,
    protected: true,
    adminOnly: true,
    accountType: 'PREMIUM',
  },
  {
    key: 'short-url-redirect',
    title: 'Short URL Redirect',
    path: '/s/:id',
    enabled: true,
    component: ShortURLRedirect,
    menu: false,
    protected: false,
    icon: Link,
  },
  {
    key: 'transaction-route',
    title: 'Transaction',
    path: '/transactions/:id',
    enabled: true,
    component: TransactionDashboard,
    icon: ListAlt,
    menu: false,
    protected: true,
  },
  {
    key: 'listing-by-slug-route',
    title: 'Listing',
    path: '/:listingSlug',
    enabled: true,
    component: ListingBySlug,
    icon: ListAlt,
    menu: false,
    protected: false,
  },
  {
    key: 'clients-admin',
    title: 'Clients',
    path: '/admin/clients',
    enabled: true,
    component: Clients,
    icon: PeopleOutline,
    menu: true,
    protected: true,
    adminOnly: true,
    accountType: 'PREMIUM',
  },
  {
    key: 'vendors-route',
    title: 'Vendors',
    path: '/vendors',
    enabled: true,
    component: Vendors,
    icon: HandshakeOutlined,
    menu: true,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'inspections-route',
    title: 'Inspections',
    path: '/inspections',
    enabled: true,
    component: Inspections,
    icon: SearchOutlined,
    menu: true,
    protected: true,
    adminOnly: true,
  },
  {
    key: 'settings-route',
    title: 'Settings',
    path: '/settings',
    enabled: true,
    component: SettingsPage,
    icon: Settings,
    menu: true,
    protected: true,
    sellerOnly: false,
  },
  {
    key: 'showing-survey-route',
    title: 'Showing Survey',
    path: '/showing-survey/:id',
    enabled: true,
    component: ShowingSurvey,
    icon: AssignmentOutlined,
    menu: false,
    protected: false,
  },
];
