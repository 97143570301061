/* eslint-disable max-len */
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { InspectionType, listings, ListingType } from '@prisma/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import inspectionsService from '../../api/services/inspections';
import { useAuth } from '../../contexts/AuthContext';
import { upperCase } from 'lodash';
import AutoComplete from '../../pages/Listings/Autocomplete';
import { AddressSelection } from '../../types/AddressSelection';
import listingsService from '../../api/services/listings';
import addDays from 'date-fns/addDays';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MuiTelInput } from 'mui-tel-input';

interface Props {
  onSuccess: () => void;
}

const CreateInspectionModal = NiceModal.create<Props>(({ onSuccess }) => {
  const modal = useModal();

  const formik = useFormik({
    initialValues: {
      type: [] as InspectionType[],
      address: null as AddressSelection | null,
      inpsectionDate: addDays(new Date(), 5),
      buyerPhone: '',
    },
    validationSchema: Yup.object({
      type: Yup.array()
        .of(Yup.string())
        .min(1, 'At least one type is required'),
      address: Yup.object().required('Address is required'),
      buyerPhone: Yup.string().required('Buyer phone is required'),
      inpsectionDate: Yup.date()
        .required('Inspection date is required')
        .min(
          addDays(new Date(), 1),
          'Inspection date must be at least tomorrow'
        ),
    }),
    onSubmit: async (values) => {
      if (!values.address) {
        return;
      }

      try {
        const listing = await listingsService.create(values.address.id);
        await inspectionsService.create({
          type: values.type,
          buyerPhone: values.buyerPhone,
          inspectionDate: values.inpsectionDate,
          lat: values.address.latitude,
          lng: values.address.longitude,
          listing: {
            connect: {
              id: listing.id,
            },
          },
        });
        onSuccess();
        modal.remove();
      } catch (error) {
        console.error(error);
      }
    },
  });
  const inpsectionTypes = ['Home', 'Termite', 'Radon', 'Sewer'];

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      aria-labelledby="create-inspection-modal"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create Inspection</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <AutoComplete
              handleChange={(e) => formik.setFieldValue('address', e)}
              property={formik.values.address}
            />
            <FormControl
              fullWidth
              error={formik.touched.type && Boolean(formik.errors.type)}
            >
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                name="type"
                multiple
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Type"
              >
                {inpsectionTypes.map((type) => (
                  <MenuItem key={type} value={upperCase(type)}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.type && formik.errors.type && (
                <FormHelperText>{formik.errors.type}</FormHelperText>
              )}
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Inspection Date"
                value={formik.values.inpsectionDate}
                onChange={(date) =>
                  formik.setFieldValue('inpsectionDate', date)
                }
                minDate={addDays(new Date(), 1)}
                maxDate={addDays(new Date(), 30)}
                slotProps={{
                  textField: {
                    error:
                      formik.touched.inpsectionDate &&
                      Boolean(formik.errors.inpsectionDate),
                    helperText:
                      formik.touched.inpsectionDate &&
                      formik.errors.inpsectionDate
                        ? (formik.errors.inpsectionDate as string)
                        : 'The last day in which the inspection can be completed',
                  },
                }}
              />
            </LocalizationProvider>
            <MuiTelInput
              label="Buyer Phone"
              value={formik.values.buyerPhone}
              onChange={(value) => formik.setFieldValue('buyerPhone', value)}
              onlyCountries={['US']}
              defaultCountry="US"
              forceCallingCode={true}
              fullWidth
              error={
                formik.touched.buyerPhone && Boolean(formik.errors.buyerPhone)
              }
              helperText={
                formik.touched.buyerPhone && formik.errors.buyerPhone
                  ? (formik.errors.buyerPhone as string)
                  : 'Your buyer will receive a link to receive quotes and schedule an inspection'
              }
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modal.hide()} variant="outlined" color="error">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default CreateInspectionModal;
