import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Stack,
  styled,
  tableRowClasses,
} from '@mui/material';
import { useAppDispatch, RootState } from '../../store/store';
import { selectActiveSellingOffers } from '../../store/selectors/offers';
import { fetchOffers } from '../../store/slices/offers';
import { formatCurrency } from '../../utils/formatCurrency';
import { capitalize } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';
import { selectMySellingListings } from '../../store/selectors/listings';
import { InfoOutlined } from '@mui/icons-material';
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip';
import Proceeds from './Proceeds';
import { fetchListings } from '../../store/slices/listings';
import { rankOffers } from '../../utils/offerScoring';
import { OfferActions } from '../../components/OfferActions/OfferActions';

const Offers: React.FC = () => {
  const dispatch = useAppDispatch();
  const offers = useSelector((state: RootState) =>
    selectActiveSellingOffers(state)
  );
  const myListings = useSelector((state: RootState) =>
    selectMySellingListings(state)
  );
  const myListing = myListings[0];
  const rankedOffers = rankOffers(offers, myListings[0]?.price ?? 0);

  const dispatchOffers = () => {
    dispatch(fetchOffers());
    dispatch(fetchListings({}));
  };

  useEffect(() => {
    dispatchOffers();
  }, [dispatch]);

  const columns = [
    'Buyer',
    'Price',
    'Payment Method',
    'Inspection',
    'Closing Date',
    'Earnest Money',
    'Contingent',
    "Buyer's Commissions",
    'Additional Terms',
    'Estimated Proceeds',
    'Score',
    '',
  ];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: '20px',
      padding: 0,
    },
  }));

  const transposedData = columns.map((col, index) => {
    return {
      columnName: col,
      data: rankedOffers.map((offer: any) => {
        switch (index) {
          case 0:
            return `${offer.user.firstName} ${offer.user.lastName}`;
          case 1:
            return formatCurrency(offer.price);
          case 2:
            return capitalize(offer.paymentMethod);
          case 3:
            return offer.inspectionPeriod ? (
              <Typography variant="body2" component={'span'}>
                Yes,{' '}
                <Typography variant="body2" component={'span'} fontWeight={500}>
                  {offer.inspectionPeriod} days
                </Typography>
              </Typography>
            ) : (
              'No'
            );
          case 4:
            return (
              <Typography variant="body2" component={'span'}>
                {new Date(offer.closingDate).toLocaleDateString()},&nbsp;
                <Typography variant="body2" component={'span'} fontWeight={500}>
                  {differenceInCalendarDays(
                    new Date(offer.closingDate),
                    new Date()
                  )}{' '}
                  days
                </Typography>
              </Typography>
            );
          case 5:
            return formatCurrency(offer.earnestMoney);
          case 6:
            return offer.contingent ? 'Yes' : 'No';
          case 7:
            return `${offer.bagentComm}%`;
          case 8:
            return offer.additionalTerms === ''
              ? 'None'
              : offer.additionalTerms;
          case 9:
            return (
              <Proceeds listingId={myListing?.id ?? ''} offerId={offer.id} />
            );
          case 10:
            if (offer.relativeScore > 0) {
              return (
                <Typography
                  variant="body1"
                  color="success.main"
                  fontWeight={700}
                >
                  +{offer.relativeScore}
                </Typography>
              );
            } else {
              return (
                <Typography variant="body2">{offer.relativeScore}</Typography>
              );
            }
          case 11: {
            return (
              <OfferActions
                offer={offer}
                onActionComplete={dispatchOffers}
                onOfferAccepted={dispatchOffers}
              />
            );
          }
          default:
            return '';
        }
      }),
    };
  });

  const renderColName = (colName: string) => {
    if (colName === 'Score') {
      return (
        <CustomTooltip
          title={
            <Box p={1}>
              <Typography variant="body2">
                We calculate a score for offers based on these critieria.
              </Typography>
              <ul>
                <li>Cash vs Financing</li>
                <li>Soonest Closing Date</li>
                <li>Proceeds</li>
                <li>Inspection Required</li>
              </ul>
            </Box>
          }
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={500}>
              Score
            </Typography>
            <InfoOutlined fontSize="small" />
          </Stack>
        </CustomTooltip>
      );
    }
    return colName;
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Typography variant="h6" fontWeight={500}>
          Offers
        </Typography>
        {/* <Button variant="contained">Call for highest offers</Button> */}
      </Stack>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {rankedOffers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Typography textAlign={'center'} p={2}>
                      You currently have no offers
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {/* <TableRow>
                <TableCell></TableCell>
                {rankedOffers.map((_, index) => (
                  <TableCell key={index} sx={{ fontWeight: 600 }}>
                    Offer {index + 1}
                  </TableCell>
                ))}
              </TableRow> */}
            </TableHead>
            <TableBody>
              {rankedOffers.length > 0 &&
                transposedData.map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    <TableCell sx={{ fontWeight: 600 }}>
                      {renderColName(row.columnName)}
                    </TableCell>
                    {row.data.map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          borderLeft: '1px solid #eee',
                        }}
                      >
                        {cell}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};

export default Offers;
