import { useState, useEffect } from 'react';
import io from 'socket.io-client';

// Create socket instance with auth headers
const socket = io(import.meta.env.VITE_BACKEND_URL || 'http://localhost:3030', {
  autoConnect: false,
  transports: ['websocket'],
});

// Custom hook for socket state management
export const useSocket = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
      socket.emit(
        'create',
        'authentication',
        {
          strategy: 'jwt',
          accessToken: localStorage.getItem('jwtToken'),
        },
        function (error: any, _newAuthResult: any) {
          if (error) {
            console.error(error);
          }
        }
      );
    };

    const onDisconnect = (reason: string) => {
      setIsConnected(false);
      console.log('Socket disconnected:', reason);
    };

    const onConnectError = (error: any) => {
      setIsConnected(false);
      if (error.message === 'unauthorized') {
        console.error('Socket authentication failed');
        // Handle unauthorized error (e.g., redirect to login)
      } else {
        console.error('Socket connection error:', error);
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onConnectError);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);
    };
  }, []);

  return {
    socket,
    isConnected,
    connect: connectSocket,
    disconnect: disconnectSocket,
  };
};

// Function to connect socket with current auth token
export const connectSocket = () => {
  socket.connect();
};

// Function to disconnect socket
export const disconnectSocket = () => {
  socket.disconnect();
};

export default socket;
