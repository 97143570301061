/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AgentLanding from '/agent_landing.svg';
import { PopupModal } from 'react-calendly';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowUpward,
  Assignment,
  CheckCircleOutline,
  Person,
} from '@mui/icons-material';

const AgentLandingV2 = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const veryMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selected, setSelected] = useState<string | null>(
    'Transaction Management'
  );

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  };

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const id = location.hash.substring(1); // Remove the '#' character
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const worksForYou = [
    {
      title: 'Transaction Management',
      bullets: [
        'Centralized dashboard for transactions.',
        'Automated notification and reminders for updates.',
        'Vendor quotes requested and delivered automatically.',
        'Clear milestone tracking.',
      ],
      image: '/demo_transactions.png',
    },
    {
      title: 'Client Empowerment',
      bullets: [
        'Clients start listings independently.',
        'Buyers can tour independent of your schedule.',
        'Easy, guided offer submissions.',
        'Clear transaction progress tracking.',
        'Automated vendor quotes for inspections.',
      ],
      image: '/renovations.png',
    },
    {
      title: 'Modern Showing Solutions',
      bullets: [
        'Showing management and scheduling.',
        'Free smart lockboxes connected to the platform.',
        'Offer submissions.',
        'Messaging and communication tools.',
      ],
      image: '/listing_detail.png',
    },
    {
      title: 'Expand Your Business',
      bullets: [
        'Attract more clients with flexible service models.',
        'Grow reach with an integrated showing network.',
        'Focus on strategy and client relationships.',
      ],
      image: '/Product1.png',
    },
    {
      title: 'Streamlined Workflows',
      bullets: [
        'Open house check-ins.',
        'Survey based showing feedback.',
        'Automated vendor quotes for inspections.',
        'Offer submissions and updates.',
        'Automatic follow up for showing feedback, home updates, and more.',
      ],
      image: '/demo_4.png',
    },
  ];

  const InfoCard = ({
    title,
    subtitle,
    icon,
  }: {
    title: string;
    subtitle: string;
    icon: React.ReactNode;
  }) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction={'column'} spacing={1} alignItems={'center'}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              {icon}
            </IconButton>
            <Typography variant="body1">
              <b>{title}</b>
            </Typography>
          </Stack>
          <Typography variant="body1">{subtitle}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Grid container spacing={2} p={mobile ? 0 : 2}>
        <Grid xs={12} sm={6}>
          <Stack
            direction={'column'}
            textAlign={mobile ? 'center' : undefined}
            spacing={2}
          >
            <Stack mb={1} mt={mobile ? 0 : -1} spacing={1}>
              <Typography variant={mobile ? 'h4' : 'h3'} fontWeight={700}>
                Empower Your Real Estate Business with Modern Solutions
              </Typography>
              <Typography variant="h6">
                Homepoint helps agents and brokerages streamline operations,
                delight clients, and diversify their business.
              </Typography>
            </Stack>
            <Button
              onClick={() => setCalendlyOpen(true)}
              variant="contained"
              color="highlight"
              sx={{ fontSize: '1.2rem', mt: 2, fontWeight: 600 }}
            >
              Get Started
            </Button>
            <PopupModal
              url="https://calendly.com/trey-homepoint"
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById('root') as HTMLElement}
              onModalClose={() => setCalendlyOpen(false)}
              open={calendlyOpen}
            />
          </Stack>
        </Grid>
        {!veryMobile && (
          <Grid sm={6}>
            <img src={AgentLanding} style={{ width: '100%' }} alt="For Sale" />
          </Grid>
        )}
      </Grid>

      {/* Customer Problem */}
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2} textAlign={'center'}>
          <Grid xs={12}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              The Tools You Need to Stay Ahead
            </Typography>
          </Grid>
          <Grid xs={12} sm={4}>
            <InfoCard
              title="Simplify Transactions"
              // eslint-disable-next-line max-len
              subtitle="AI-powered transaction management with inspection coordination, offer submissions, and more."
              icon={<Assignment sx={{ fontSize: 25 }} />}
            />
          </Grid>
          <Grid xs={12} sm={4}>
            <InfoCard
              title="Grow Smarter"
              // eslint-disable-next-line max-len
              subtitle="Focus on strategy and scale with features built for flexible business models and modern clients."
              icon={<ArrowUpward sx={{ fontSize: 25 }} />}
            />
          </Grid>
          <Grid xs={12} sm={4}>
            <InfoCard
              title="Empower Clients"
              // eslint-disable-next-line max-len
              subtitle="Give clients the tools to take control of their real estate journey, handling key steps independently while staying supported by your expertise."
              icon={<Person sx={{ fontSize: 25 }} />}
            />
          </Grid>
        </Grid>
      </Container>

      <Divider sx={{ opacity: 0.75, margin: 6 }} />

      {/* Guide */}
      <Stack
        direction={'column'}
        spacing={2}
        p={2}
        sx={{
          filter: `drop-shadow(0 16px 64px ${theme.palette.primary.main}40)`,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          See how Homepoint works for you
        </Typography>
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <div
              style={{
                overflow: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              <ToggleButtonGroup
                sx={{
                  '& .MuiToggleButton-root': {
                    fontSize: mobile ? 14 : undefined,
                    borderRadius: '30px',
                    mx: 1,
                    px: mobile ? 1 : 4,
                    border: `1px solid ${theme.palette.primary.main}`,
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  },
                  display: 'inline-flex',
                  whiteSpace: 'nowrap',
                }}
                exclusive
                value={selected}
                onChange={(_, value) => {
                  // Only update if a new value is selected (prevent deselection)
                  if (value !== null) {
                    setSelected(
                      worksForYou.find((item) => item.title === value)?.title ??
                        null
                    );
                    // Scroll the clicked button into view if needed
                    const button = document.querySelector(`[value="${value}"]`);
                    button?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                      inline: 'center',
                    });
                  }
                }}
              >
                {worksForYou.map((item) => (
                  <ToggleButton key={item.title} value={item.title}>
                    {item.title}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
            <Grid container spacing={2} sx={{ mt: 4 }}>
              <Grid xs={12} sm={8} pl={2}>
                <img
                  src={
                    worksForYou.find((item) => item.title === selected)?.image
                  }
                  alt="Selected"
                  style={{
                    width: '100%',
                    borderRadius: 10,
                    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4} pl={mobile ? 0 : 2}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={24}
                  pl={mobile ? 2 : 0}
                >
                  {selected}
                </Typography>
                <List>
                  {worksForYou
                    .find((item) => item.title === selected)
                    ?.bullets.map((bullet) => (
                      <ListItem key={bullet}>
                        <CheckCircleOutline sx={{ mr: 1, fontSize: 18 }} />
                        <ListItemText primary={bullet} />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Stack>
      <Divider sx={{ opacity: 0.75, margin: 8 }} />

      {/* Pricing Table */}
      <Container maxWidth="lg" sx={{ mb: 8 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 600, textAlign: 'center', mb: 4 }}
        >
          Choose the Plan That&apos;s Right for You
        </Typography>
        <Grid container spacing={4}>
          <Grid xs={12} md={6} sx={{ margin: '0 auto' }}>
            <Card
              sx={{
                height: '100%',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
              }}
            >
              <CardContent>
                <Stack spacing={3}>
                  <Stack>
                    <Typography variant="h5" fontWeight={600}>
                      Premium
                    </Typography>
                    <Typography variant="h4" fontWeight={700}>
                      $50/month
                    </Typography>
                  </Stack>
                  <List>
                    {[
                      'Advanced transaction management',
                      'Innovative showings, open houses, and feedback workflows',
                      'Offer management',
                      'Smart lockbox included',
                      'Priority support',
                      'Custom branding',
                    ].map((feature) => (
                      <ListItem key={feature} sx={{ py: 0.5 }}>
                        <CheckCircleOutline
                          sx={{ mr: 1, color: 'primary.contrastText' }}
                        />
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    onClick={() => setCalendlyOpen(true)}
                    variant="contained"
                    color="highlight"
                    sx={{ fontSize: '1.2rem', mt: 2, fontWeight: 600 }}
                  >
                    Get Started
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        maxWidth="lg"
        onClick={() => setSelectedImage(null)}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Enlarged view"
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </Dialog>
    </>
  );
};

export default AgentLandingV2;
