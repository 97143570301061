import {
  TableBody,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Skeleton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { useEffect } from 'react';
import { fetchInspections } from '../../store/slices/inspections';
// eslint-disable-next-line max-len
import CreateInspectionModal from '../../components/CreateInspectionModal/CreateInspectionModal';
import getInspectionStatus from '../../utils/getInspectionStatus';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip';

const Inspections = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { inspections, loading, error } = useSelector(
    (state: RootState) => state.inspections
  );
  const { vendors } = useSelector((state: RootState) => state.vendors);

  useEffect(() => {
    dispatch(fetchInspections());
  }, []);

  if (loading) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  const disableCreate = vendors.length < 2;

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h6">Inspections</Typography>
        <CustomTooltip
          title={
            disableCreate
              ? 'At least 2 vendors are required to create an inspection'
              : ''
          }
        >
          <span>
            <Button
              variant="contained"
              color="highlight"
              onClick={() => {
                NiceModal.show(CreateInspectionModal, {
                  onSuccess: () => {
                    dispatch(fetchInspections());
                  },
                });
              }}
              disabled={disableCreate}
            >
              Create Inspection
            </Button>
          </span>
        </CustomTooltip>
      </Stack>
      {inspections.length === 0 ? (
        <Paper sx={{ p: 3 }}>
          <Typography variant="body1" align="center">
            No inspections found
          </Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Inspection Date</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {inspections.map((inspection) => (
                <TableRow key={inspection.id}>
                  <TableCell>{inspection.listing?.address}</TableCell>
                  <TableCell>
                    {getInspectionStatus(inspection.status)}
                  </TableCell>
                  <TableCell>
                    {inspection.inspectionDate
                      ? new Date(inspection.inspectionDate).toLocaleDateString()
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/inspections/${inspection.id}`)}
                      fullWidth
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default Inspections;
