import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

type VendorUpdateInput = Omit<Prisma.vendorsUpdateInput, 'sampleReportUrl'> & {
  sampleReportUrl?: FormData;
};

const vendorsService = {
  create: async (vendor: Prisma.vendorsCreateInput) => {
    const res = await axiosInstance.post('/vendors', vendor);
    return res.data;
  },
  find: async () => {
    const res = await axiosInstance.get('/vendors');
    return res.data;
  },
  patch: async (id: string, data: VendorUpdateInput) => {
    const res = await axiosInstance.patch(`/vendors/${id}`, data);
    return res.data;
  },
  post: async (vendor: Prisma.vendorsCreateInput) => {
    const res = await axiosInstance.post('/vendors', vendor);
    return res.data;
  },
};

export default vendorsService;
