import { groupBy } from 'lodash';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectMessages = (state: RootState) => state.messages.messages;

export const selectMessagesGroupedByBuyer = createSelector(
  [selectMessages],
  (messages) => {
    return groupBy(messages, 'senderId');
  }
);
