import { Typography } from '@mui/material';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

const RedactText = () => {
  return (
    <CustomTooltip title="Details are not shown until referral fee is paid.">
      <Typography
        component="span"
        sx={{
          backgroundColor: '#E0E0E0',
          borderRadius: '4px',
          padding: '2px 4px',
          color: 'transparent',
          userSelect: 'none',
          width: '100%',
          height: 25,
          maxWidth: '300px',
          display: 'inline-block',
        }}
      />
    </CustomTooltip>
  );
};

export default RedactText;
